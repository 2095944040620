import { Box, FormLabel, HStack, Stack, Select } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { useGetAllCarMakesQuery, useGetMakeModelsQuery } from '../../../public-pages/home/components/service';
import { FormContentWrapper, InputField, SelectInputField } from './FormContentWrapper';

export const ListingInformation = ({ vehicleListingDetails, setVehicleListingDetails }) => {
  const { data: carMakes } = useGetAllCarMakesQuery();
  const carModel = carMakes?.data?.find((make) => make?.name === vehicleListingDetails?.make);
  const { data: models } = useGetMakeModelsQuery(carModel?.id);

  return (
    <FormContentWrapper title='Listing Information'>
      <Stack spacing='10'>
        <HStack>
          {/* <SelectInputField label='Make' /> */}
          <Box w='100%'>
            <FormLabel m='0'>Make</FormLabel>
            <Select value={vehicleListingDetails.make || ''} onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, make: e.target.value }))}>
              {carMakes?.data?.map((make, i) => (
                <Fragment key={nanoid()}>
                  {i === 0 && (
                    <option value='' disabled>
                      Select Car make
                    </option>
                  )}
                  <option disabled={make?.disabled} value={make?.name}>
                    {make?.name}
                  </option>
                </Fragment>
              ))}
            </Select>
          </Box>
          <Box w='100%'>
            <FormLabel m='0'>Model</FormLabel>
            <Select
              placeholder='Select model (pick a make first)'
              value={vehicleListingDetails.model_id}
              onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, model_id: e.target.value }))}
            >
              {models?.data?.map((model, i) => (
                <option key={nanoid()} value={model?.id}>
                  {model?.name}
                </option>
              ))}
            </Select>
          </Box>
        </HStack>

        <HStack justify='space-between'>
          <InputField label='Year' name='year' type='number' vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />

          <InputField label='VIN' name='vin' vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />
        </HStack>

        <HStack>
          <InputField label='Mileage' name='mileage' vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />
          <SelectInputField
            label='Warranty'
            placeholder={'Warranty in years'}
            options={['No Warranty', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']}
            name='warranty'
            value={vehicleListingDetails.warranty}
            setVehicleListingDetails={setVehicleListingDetails}
          />
        </HStack>

        <HStack rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
          <SelectInputField
            label='Fuel type'
            placeholder={'Vehicle Fuel type'}
            name='fuel_Type'
            value={vehicleListingDetails.fuel_Type}
            options={['Gasoline/Petrol', 'Diesel', 'Electric']}
            setVehicleListingDetails={setVehicleListingDetails}
          />
          <SelectInputField
            label='Transmission'
            placeholder='Transmission Type'
            name='transmission'
            value={vehicleListingDetails.transmission}
            options={['Manual', 'Automatic']}
            setVehicleListingDetails={setVehicleListingDetails}
          />
        </HStack>
      </Stack>
    </FormContentWrapper>
  );
};
