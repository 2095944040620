import { FormLabel, HStack, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { labelStyle } from '../../../../utils/theme';
import { FormContentWrapper, SelectInputField } from './FormContentWrapper';
import { carTypes, colorsArr } from './extras';
import { nanoid } from '@reduxjs/toolkit';

export const MajorFeatures = ({ vehicleListingDetails, setVehicleListingDetails }) => {
  return (
    <FormContentWrapper title='Major Features'>
      <FormLabel {...labelStyle}>Car(Body) type</FormLabel>{' '}
      <Select value={vehicleListingDetails.body_style || ''} onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, body_style: e.target.value }))}>
        {carTypes?.map((make, i) => (
          <Fragment key={nanoid()}>
            {i === 0 && (
              <option value='' disabled>
                Select Car type for example Truck
              </option>
            )}
            <option disabled={make?.disabled} value={make.value}>
              {make.label}
            </option>
          </Fragment>
        ))}
      </Select>
      <HStack pt='5' rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
        <SelectInputField
          placeholder={'number of doors'}
          label='Doors'
          name='door'
          options={['2', '4']}
          value={vehicleListingDetails?.door}
          setVehicleListingDetails={setVehicleListingDetails}
        />

        <SelectInputField
          label='Drive Train'
          placeholder='Drive'
          name='driveTrain'
          options={['Rear Wheel Drive – RWD', 'Four Wheel Drive – 4WD', 'All-Wheel Drive – AWD', 'Front Wheel Drive – FWD']}
          value={vehicleListingDetails?.driveTrain}
          setVehicleListingDetails={setVehicleListingDetails}
        />
      </HStack>
      <HStack pt='5' rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
        <SelectInputField
          placeholder='Inside Color'
          label='Interior Color'
          name='interior_color'
          options={colorsArr}
          value={vehicleListingDetails?.interior_color}
          setVehicleListingDetails={setVehicleListingDetails}
        />
        <SelectInputField
          label='Exterior color'
          placeholder='Outer Color'
          name='exterior_color'
          value={vehicleListingDetails?.exterior_color}
          options={colorsArr}
          setVehicleListingDetails={setVehicleListingDetails}
        />

        <SelectInputField
          label='Seat'
          placeholder='Number of Seats'
          name='seat'
          options={['2', '4', '6', '8']}
          value={vehicleListingDetails?.seat}
          setVehicleListingDetails={setVehicleListingDetails}
        />
      </HStack>
      <RadioGroup onChange={(val) => setVehicleListingDetails((prev) => ({ ...prev, category: val }))} value={vehicleListingDetails?.category} py='5'>
        <FormLabel>Category</FormLabel>
        <Stack direction='row'>
          <Radio value='trending'>Trending</Radio>
          <Radio value='popular'>Popular</Radio>
          {/* <Radio value='favourite'>Favourite</Radio> */}
          <Radio value='used'>Used</Radio>
          <Radio value='new'>New</Radio>
        </Stack>
      </RadioGroup>
    </FormContentWrapper>
  );
};
