import { useState } from 'react';
import { AuthPagesBody } from '.';
import { Box, Center, FormControl, FormLabel, Img, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { PrimaryButton } from '../../components/common/CustomButtons';
import { Link, useSearchParams } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../app/routes';
import { useForm } from 'react-hook-form';
import { GrFormViewHide, GrFormView } from 'react-icons/gr';
import { useResetPasswordMutation } from './service';
import { errorToast, successToast } from '../../components/common/ToastHandler';

export const SetNewPassword = () => {
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, formState, watch } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  if (!token || !email) {
    console.log('Missing token or email');
  }

  const handleSetNewPassword = async (data) => {
    const payload = { email, token, ...data };
  
    try {
      const response = await resetPassword(payload).unwrap();
      console.log('Success:', response); // Log success response
      successToast(response.message);
    } catch (error) {
      console.error('Error:', error); // Log error for debugging
  
      if (error?.data?.message === 'You cannot reuse your old password') {
        errorToast('You cannot reuse your old password');
      } else {
        errorToast('Password reset failed. Please try again.');
      }
    }
  };
  

  return (
    <AuthPagesBody>
      <Center minH='75vh' flexDirection='column'>
        <FormControl as={'form'} maxW='400px' mx='auto' onSubmit={handleSubmit(handleSetNewPassword)}>
          <Img src='/images/Lock.png' alt='Lock' />
          <Text textStyle='h3' fontSize='2rem'>
            Set A New Password?
          </Text>

          <FormLabel m='0' htmlFor='password' mt='2rem'>
            Set New Password
          </FormLabel>

          <InputGroup size='md'>
            <Input
              id='password'
              {...register('password', {
                required: 'Password is required ',
                minLength: { value: 8, message: 'Password must be 8 Characters' },
              })}
              bg='brand.100'
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
            />
            <InputRightElement width='4.5rem'>
              <Box as='span' cursor='pointer' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <GrFormViewHide size={20} /> : <GrFormView size={20} />}
              </Box>
            </InputRightElement>
          </InputGroup>
          <Text as='small' color='crimson'>
            {formState.errors?.password?.message}
          </Text>

          <FormLabel m='0' htmlFor='password_confirmation' mt='2rem'>
            Confirm New Password
          </FormLabel>

          <InputGroup size='md'>
            <Input
              id='password_confirmation'
              {...register('password_confirmation', {
                required: 'Password confirmation is required ',
                validate: (val) => {
                  const password = watch('password');
                  if (password !== val) {
                    console.log(`Password mismatch: ${password} !== ${val}`); // Log password mismatch for debugging
                    return 'Your passwords do not match';
                  }
                },
              })}
              bg='brand.100'
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
            />
            <InputRightElement width='4.5rem'>
              <Box as='span' cursor='pointer' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <GrFormViewHide size={20} /> : <GrFormView size={20} />}
              </Box>
            </InputRightElement>
          </InputGroup>
          <Text as='small' color='crimson'>
            {formState.errors?.password_confirmation?.message}
          </Text>

          <PrimaryButton mt='10' type='submit' isLoading={isLoading} w='full'>
            Submit Password
          </PrimaryButton>

          <Text textAlign='center' py='5'>
            <Link to={PUBLIC_PATHS.LOGIN} style={{ fontWeight: 'bold' }}>
              Back to Login
            </Link>
          </Text>
        </FormControl>
      </Center>
    </AuthPagesBody>
  );
};
