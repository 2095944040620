import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, Flex, HStack, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { GoSettings } from 'react-icons/go';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
import { nanoid } from '@reduxjs/toolkit';
import { PriceRangeSlider } from './PriceRangeSlider';
import { useGetAllCarMakesQuery, useGetMakeModelsQuery } from '../../home/components/service';
import { colorsArr } from '../../../admin/add-new-vehicle/components/extras';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { BiReset } from 'react-icons/bi';
import { getYearRanges } from '../../home/components/extras';
import { useEffect, useState } from 'react';

export const FilterBy = ({ setParams, params }) => {
  const [skip, setSkip] = useState(true);
  const { data: allCarMakes, isLoading } = useGetAllCarMakesQuery();
  const carMake = allCarMakes?.data.find((make) => make?.name === params?.make);
  const { data: carModels } = useGetMakeModelsQuery(carMake?.id, { skip });
  const [yearRanges, setYearRanges] = useState([]);

  useEffect(() => {
    carMake?.id && setSkip(false);
  }, [carMake?.id]);

  const handleModelSelection = (selected) => {
    setParams((prev) => ({ ...prev, model: selected }));

    const selectedCarModel = carModels?.data?.find((car) => car.name === selected);

    setYearRanges(getYearRanges(selectedCarModel?.start_year, selectedCarModel?.end_year || new Date().getFullYear(), 5));
  };

  const handleReset = () => {
    const newObject = {};
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        newObject[key] = '';
      }
    }
    setParams(newObject);
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack w='300px' order={[-1, -1, 1]}>
      <HStack className='vehicle__filter__settings' fontWeight={'bold'}>
        <Flex>
          <GoSettings />
          <Text>Filter by</Text>
        </Flex>
        <Button bg='brand.500' color='white' size='xs' leftIcon={<BiReset />} onClick={handleReset}>
          Reset filters
        </Button>
      </HStack>

      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Make
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.make} onChange={(val) => setParams((prev) => ({ ...prev, make: val }))}>
                  <Stack h='150px' overflowY='auto'>
                    {allCarMakes?.data?.map((make) => (
                      <Radio key={nanoid()} value={make?.name}>
                        {make?.name}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Model
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.model} onChange={(val) => handleModelSelection(val)}>
                  <Stack h='150px' overflowY='auto'>
                    {carModels?.data.length ? (
                      carModels?.data?.map((make) => (
                        <Radio key={nanoid()} value={make?.name}>
                          {make?.name}
                        </Radio>
                      ))
                    ) : (
                      <Text>Select Car Make </Text>
                    )}
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Year
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.year} onChange={(val) => setParams((prev) => ({ ...prev, year: val }))}>
                  <Stack h='150px' overflowY={'auto'}>
                    {yearRanges.length ? (
                      yearRanges?.map((range) => (
                        <Radio key={nanoid()} value={range}>
                          {range}
                        </Radio>
                      ))
                    ) : (
                      <Text>Select Car Make and Model</Text>
                    )}
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Transmission
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.transmission} onChange={(val) => setParams((prev) => ({ ...prev, transmission: val }))}>
                  <Stack>
                    <Radio value={'manual'}>Manual</Radio>
                    <Radio value={'automatic'}>Automatic</Radio>
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Interior Color
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.interior_color} onChange={(val) => setParams((prev) => ({ ...prev, interior_color: val }))}>
                  <Stack h='150px' overflowY={'auto'}>
                    {colorsArr?.map((color) => (
                      <Radio key={color} value={color}>
                        {color}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        {/* exterior_color */}
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Exterior Color
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RadioGroup colorScheme={'brand'} value={params?.exterior_color} onChange={(val) => setParams((prev) => ({ ...prev, exterior_color: val }))}>
                  <Stack h='150px' overflowY={'auto'}>
                    {colorsArr?.map((color) => (
                      <Radio key={color} value={color}>
                        {color}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Text textStyle='h3' fontSize='16px' as='span' flex='1' textAlign='left'>
                    Price
                  </Text>
                  {isExpanded ? <BsDashCircle size={18} /> : <BsPlusCircle size={18} />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <PriceRangeSlider setParams={setParams} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};
