import { Flex, Img, Text, VStack } from '@chakra-ui/react';

export const Testimonial = () => {
  return (
    <VStack maxW='600px' mx='auto' textAlign='center' py='10' spacing='5'>
      <Text textStyle='h2'>Testimonials</Text>

      <Text fontWeight={600} fontSize='16px' py='3'>
        “Gabe Investments has been my go-to car person since 2014. We have purchased all our vehicles from them and can attest to their reliability
        and affordability"
      </Text>
      <Text>
        <strong>Anthony T,</strong> Buyer{' '}
      </Text>
      <Flex py='10'>
        <Img src='/images/Frame.png' />
      </Flex>
    </VStack>
  );
};
