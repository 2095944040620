import { Box, Center, Flex, FormControl, FormLabel, HStack, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { AuthPagesBody } from '.';
import { GrFormViewHide, GrFormView } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../app/routes';
import { PrimaryButton } from '../../components/common/CustomButtons';
import { login } from './service';
import { useDispatch } from 'react-redux';
import { warningToast } from '../../components/common/ToastHandler';

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formValues.password.length < 8) return warningToast('Invalid Inputs');

    login(dispatch, formValues, setLoading, navigate);
  };

  return (
    <AuthPagesBody>
      {' '}
      <Center minH='75vh' flexDirection='column'>
        <FormControl as={'form'} maxW='400px' mx='auto' onSubmit={onSubmit}>
          <Text textStyle='h3' fontSize='2rem'>
            Welcome Back
          </Text>
          <Text fontSize='1rem' mb='2rem'>
            Welcome back! Kindly enter your details.
          </Text>
          <FormLabel m='0' htmlFor='email'>
            Email
          </FormLabel>
          <Input value={formValues?.email} name='email' onChange={onChange} type='email' required bg='brand.100' placeholder='example@mail.com' />

          <FormLabel m='0' htmlFor='password' mt='2rem'>
            Password
          </FormLabel>

          <InputGroup size='md'>
            <Input
              value={formValues?.password}
              onChange={onChange}
              required
              name='password'
              bg='brand.100'
              id='password'
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
            />
            <InputRightElement width='4.5rem'>
              <Box as='span' cursor='pointer' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <GrFormViewHide size={20} /> : <GrFormView size={20} />}
              </Box>
            </InputRightElement>
          </InputGroup>

          <HStack my='5' justify='space-between'>
            <Text color='brand.500' fontWeight='semibold'>
              <Link to={PUBLIC_PATHS.FORGOT_PASSWORD}>Forgot Password</Link>
            </Text>
          </HStack>

          <Flex flexDirection='column' gap='5'>
            <PrimaryButton isLoading={loading} type='submit'>
              Login
            </PrimaryButton>
          </Flex>
          <Text textAlign='center' py='5'>
            Don’t have an account?{' '}
            <Link to={PUBLIC_PATHS.SIGN_UP} style={{ fontWeight: 'bold' }}>
              Sign up for free
            </Link>
          </Text>
        </FormControl>
      </Center>
    </AuthPagesBody>
  );
};
