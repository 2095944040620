import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authenticationApiSlice } from '../pages/auth-pages/service';
import userReducer from './features/userSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { inventoryApiSlice } from '../pages/public-pages/inventory/service';
import { modelApiSlice } from '../pages/public-pages/home/components/service';
import { listingApiSlice } from '../pages/admin/listings/service/listingApi';
import { profileApiSlice } from './features/api/profileApiSlice';
import { superAdminApiSlice } from './features/api/superAdminApilSlice';
import { contactUsApiSlice } from './features/api/contactUs';
import { messagesApiSlice } from './features/api/messagesApiSlice';
import { subscribersApiSlice } from './features/api/subscribersApiSlice';

const persistConfig = {
  key: '#gbe#xp&st',
  version: 1,
  storage,
};

const rootReducer = combineReducers({ user: userReducer });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    [authenticationApiSlice.reducerPath]: authenticationApiSlice.reducer,
    [modelApiSlice.reducerPath]: modelApiSlice.reducer,
    [inventoryApiSlice.reducerPath]: inventoryApiSlice.reducer,
    [listingApiSlice.reducerPath]: listingApiSlice.reducer,
    [profileApiSlice.reducerPath]: profileApiSlice.reducer,
    [superAdminApiSlice.reducerPath]: superAdminApiSlice.reducer,
    [contactUsApiSlice.reducerPath]: contactUsApiSlice.reducer,
    [messagesApiSlice.reducerPath]: messagesApiSlice.reducer,
    [subscribersApiSlice.reducerPath]: subscribersApiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authenticationApiSlice.middleware,
      modelApiSlice.middleware,
      inventoryApiSlice.middleware,
      listingApiSlice.middleware,
      profileApiSlice.middleware,
      superAdminApiSlice.middleware,
      contactUsApiSlice.middleware,
      messagesApiSlice.middleware,
      subscribersApiSlice.middleware
    ),
});

export let persistor = persistStore(store);
