import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINTS } from '../../../services/routes.constants';

export const contactUsApiSlice = createApi({
  reducerPath: 'contactUsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['contactUsApiSlice'],

  endpoints: (builder) => ({
    publicContactUs: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: API_ENDPOINTS.CONTACT_US,
        body,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: ['contactUsApiSlice'],
    }),

    getAllContacts: builder.query({
      query: () => API_ENDPOINTS.CONTACT_US,
      providesTags: ['contactUsApiSlice'],
    }),

    deleteContactDetails: builder.mutation({
      query: (contactId) => ({
        url: API_ENDPOINTS.DELETE_CONTACT(contactId),
        method: 'DELETE',
      }),
      invalidatesTags: ['contactUsApiSlice'],
    }),
  }),
});

export const { usePublicContactUsMutation, useGetAllContactsQuery, useDeleteContactDetailsMutation } = contactUsApiSlice;
