import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { headers } from '../../../../../services/api';
import { API_ENDPOINTS } from '../../../../../services/routes.constants';

export const modelApiSlice = createApi({
  reducerPath: 'modelApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set('authorization', `Bearer ${token}`);

      return headers;
    },
  }),

  tagTypes: ['modelApiSlice'],

  endpoints: (builder) => ({
    getAllCarMakes: builder.query({
      query: () => API_ENDPOINTS.GET_MAKE,
      providesTags: ['modelApiSlice'],
    }),

    getAllCarModels: builder.query({
      query: () => API_ENDPOINTS.GET_ALL_CAR_MODELS,
      providesTags: ['modelApiSlice'],
    }),

    // getCarModels: builder.query({
    //   query: (makeId) => API_ENDPOINTS.GET_MODELS(makeId),
    //   providesTags: ['modelApiSlice'],
    // }),
    getMakeModels: builder.query({
      query: (makeId) => API_ENDPOINTS.GET_MAKE_MODELS(makeId),
      providesTags: ['modelApiSlice'],
    }),

    getCarByCategory: builder.query({
      query: (category) => API_ENDPOINTS.GET_CAR_BY_CATEGORY(category),
      providesTags: ['modelApiSlice'],
    }),

    getFavourites: builder.query({
      query: () => API_ENDPOINTS.GET_USER_FAVOURITES,
      providesTags: ['modelApiSlice'],
    }),

    markAsFavourite: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: API_ENDPOINTS.MARK_VEHICLE_AS_FAVOURITE,
        body,
        headers: headers,
      }),
      invalidatesTags: ['modelApiSlice'],
    }),
  }),
});

export const { useGetAllCarMakesQuery, useGetAllCarModelsQuery, useGetMakeModelsQuery, useGetCarByCategoryQuery, useGetFavouritesQuery, useMarkAsFavouriteMutation } =
  modelApiSlice;
