import { Box, Center, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userRole } from '../../../../redux/features/userSlice';
import { gotoBackToProfileSettings } from './extra';

export const SettingHead = ({ title, sub }) => {
  const user = useSelector(userRole);

  return (
    <HStack gap='1rem'>
      <Link to={gotoBackToProfileSettings(user)}>
        <Center bg='blackAlpha.200' borderRadius='8px' p='3' pl='5'>
          <MdArrowBackIos size={20} />
        </Center>
      </Link>
      <Box>
        <Text textStyle='h3' fontSize='18px'>
          {title || 'Account information'}
        </Text>
        <Text>{sub || 'Personal information'}</Text>
      </Box>
    </HStack>
  );
};
