import { Textarea, Stack, Input } from '@chakra-ui/react';
import { FormContentWrapper } from '../../add-new-vehicle/components/FormContentWrapper';

export const EditDescribeYourVahicle = ({ carDetails, setCarDetails }) => {
  return (
    <FormContentWrapper title='Listing Details'>
      <Stack spacing='5'>
        <Input value={carDetails?.name} onChange={(e) => setCarDetails((prev) => ({ ...prev, name: e.target.value }))} name='name' />
        <Input
          value={carDetails?.price}
          onChange={(e) => setCarDetails((prev) => ({ ...prev, price: e.target.value }))}
          placeholder='Price of Vehicle'
          name='price'
          type='number'
        />
        <Textarea
          value={carDetails?.description}
          onChange={(e) => setCarDetails((prev) => ({ ...prev, description: e.target.value }))}
          rows='7'
          resize='none'
          placeholder='Extended Description'
        />
      </Stack>
    </FormContentWrapper>
  );
};
