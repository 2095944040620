import { Circle, Flex, HStack, Select, Stack, Text } from '@chakra-ui/react';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { border } from '../../../utils/theme';
import { RiArrowDownSLine } from 'react-icons/ri';
import { SingleListedVehicle } from './components/SingleListedVehicle';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Paginator } from '../../../components/common/Paginator';
import { useGetListingsQuery } from './service/listingApi';
import { EmptyState } from '../../../components/common/EmptyState';
import { FullPageLoader } from '../../../components/common/FullPageLoader';

export const Listings = () => {
  const [listingCategory, setListingCategory] = useState('all');
  const { data: listing, isLoading } = useGetListingsQuery(listingCategory);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;

  const pagesVisited = pageNumber * itemsPerPage;
  const displayedListings = listing?.data?.slice(pagesVisited, pagesVisited + itemsPerPage);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper pb='20'>
      <HStack border={border} p='3' borderTopRadius='5px' justify='space-between'>
        <HStack gap='1.5rem'>
          <Text textStyle='h3' fontSize='17px'>
            Listings
          </Text>

          <Select onChange={(e) => setListingCategory(e.target.value.toString())} bg='white' w='150px' icon={<DropdownIcon icon={<RiArrowDownSLine />} />}>
            <option value={'all'}>All</option>
            {/* <option value='favourite'>Favorites</option> */}
            <option value='trending'>Trending</option>
            <option value='popular'>Popular</option>
            <option value='used'>Used</option>
            <option value='new'>New</option>
          </Select>
        </HStack>
      </HStack>
      <Stack spacing='10'>
        {displayedListings?.length && displayedListings?.map((item) => <SingleListedVehicle key={nanoid()} item={item} />)}
        {!displayedListings?.length && <EmptyState h='50vh' size='10rem' msg='No Current Listings' />}
      </Stack>
      <Flex>
        <Paginator data={listing?.data || 0} setPageNumber={setPageNumber} itemsPerPage={itemsPerPage} />
      </Flex>
    </PageWrapper>
  );
};

export function DropdownIcon({ icon, ...props }) {
  return (
    <Circle p='1' bg='brand.100' color='brand.500' {...props}>
      {icon}
    </Circle>
  );
}
