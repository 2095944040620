import { Divider, Square, Stack, Text } from '@chakra-ui/react';

export const SingleWhyChooseUsCard = ({ title, text, Icon }) => {
  return (
    <Stack spacing='5' className='why__choose__us__card' shadow='sm' w={['100%']}>
      <Square className='why__choose__us__icon'>
        <Icon size={20} />
      </Square>
      <Text className='card__head' textStyle='h4'>
        {title || 'Vehicle Insurance'}
      </Text>
      <Divider w='50px' h='2px' bg='brand.500' />
      <Text>{text}</Text>
    </Stack>
  );
};
// _hover={{ bg: 'brand.500', color: 'white' }}
