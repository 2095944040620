import { Box, Flex, FormControl, FormLabel, HStack, Input, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { errorToast, infoToast, successToast } from '../../../../components/common/ToastHandler';
import { useUpdatePhoneNumberMutation } from '../../../../redux/features/api/profileApiSlice';
import { disable } from '../../../../utils/utils';

export const ChangePhoneNumber = ({ onClose }) => {
  const [updatePhoneNumber, { isLoading }] = useUpdatePhoneNumberMutation();
  const [formValues, setFormValues] = useState({ old_phone_number: '', new_phone_number: '', confirmPhoneNumber: '' });

  const onChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async () => {
    const { confirmPhoneNumber, new_phone_number } = formValues;
    if (confirmPhoneNumber !== new_phone_number) return infoToast('New Phone Numbers do not match');

    await updatePhoneNumber({ old_phone_number: formValues.old_phone_number, new_phone_number: formValues.new_phone_number })
      .unwrap()
      .then((data) => {
        successToast(data.message);
        onClose();
      })
      .catch((error) => {
        errorToast();
        onClose();
      });
  };

  // 123456890

  return (
    <Stack>
      <HStack justify='space-between' p='8' shadow='sm'>
        <Text textStyle='h3' fontSize={'20px'}>
          Change Phone Number
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>
      <FormControl as='form' p='8'>
        <Stack spacing='5'>
          <FormLabel as='label' fontSize={'14px'}>
            Old Phone number
            <Input name='old_phone_number' type='number' onChange={onChange} placeholder='Enter your old phone number' />
          </FormLabel>
          <FormLabel as='label' fontSize={'14px'}>
            Enter new Phone number
            <Input name='new_phone_number' type='number' onChange={onChange} placeholder='Enter your New Phone Number' />
          </FormLabel>
          <FormLabel as='label' fontSize={'14px'}>
            Confirm new Phone number
            <Input name='confirmPhoneNumber' type='number' onChange={onChange} placeholder='Confirm Phone Number' />
          </FormLabel>
        </Stack>
        <Flex py='10'>
          <PrimaryButton isLoading={isLoading} isDisabled={!disable(formValues?.confirmPhoneNumber, formValues?.new_phone_number)} w='100%' onClick={handleUpdate}>
            Update Phone Number
          </PrimaryButton>
        </Flex>
      </FormControl>
    </Stack>
  );
};
