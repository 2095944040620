import { Box, HStack, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { formatInThousands } from '../../../../utils/utils';

export const PriceRangeSlider = ({ setParams }) => {
  const demonination = 1000;
  const [rangeValues, setRangeValues] = useState([10 * demonination, 30000 * demonination]);

  const onChangeEnd = (val) => {
    setRangeValues(val);
    setParams((prev) => ({ ...prev, price: `${val[0]}-${val[1]}` }));
  };

  return (
    <Box>
      <RangeSlider
        h='80px'
        defaultValue={rangeValues}
        min={10000}
        max={30000000}
        step={10000}
        onChange={(v) => setRangeValues(v)}
        onChangeEnd={onChangeEnd}
      >
        <RangeSliderTrack h='10px'>
          <RangeSliderFilledTrack h='10px' bg='brand.500' />
        </RangeSliderTrack>
        <HStack justify='space-between' pos='relative' top={'-10px'}>
          <Text textStyle='h4'>&#36;{formatInThousands(rangeValues[0])}</Text>
          <Text textStyle='h4'>&#36;{formatInThousands(rangeValues[1])}</Text>
        </HStack>
        <RangeSliderThumb boxSize={6} index={0} />
        <RangeSliderThumb boxSize={6} index={1} />
      </RangeSlider>
    </Box>
  );
};
