import { Box, Center, Flex, HStack, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { CgArrowLongLeft } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../../app/routes';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { PageWrapper } from '../../../../components/common/PageWrapper';
import { userRole } from '../../../../redux/features/userSlice';

const { ADMIN_LISTING, SUPER_ADMIN_LISTING } = PROTECTED_PATHS;

export const ListingCreated = () => {
  const user = useSelector(userRole);
  const navigate = useNavigate();
  const height = '110px';
  return (
    <Box bg='white' h='100vh'>
      <PageWrapper shadow='sm'>
        <HStack justify={'center'} h={height}>
          <HStack cursor='pointer' flex='0.8' onClick={() => navigate(user === 'admin' ? ADMIN_LISTING : SUPER_ADMIN_LISTING)}>
            <CgArrowLongLeft size={20} />
            <Text>Back to Dashboard</Text>
          </HStack>

          <Flex flex='1'>
            <Img src='/images/logo.svg' alt='logo' />
          </Flex>
        </HStack>
      </PageWrapper>
      <Center pb='5' pt='10rem' justifyContent='space-between' flexDirection='column' h={`calc(100vh - ${height} )`}>
        <VStack>
          <Img src='/images/success.svg' alt='success' />
          <Text textStyle='h2'>Listing created</Text>
          <Text fontSize='16px' pb='5'>
            Your new listing succesfully created please setup availability and pricing
          </Text>
          <PrimaryButton onClick={() => navigate(user === 'admin' ? ADMIN_LISTING : SUPER_ADMIN_LISTING)} w='250px' size='lg'>
            Back to dashboard
          </PrimaryButton>
        </VStack>
        <Flex justify='flex-end' w='100%'>
          <Img src='/images/empty_street.svg' alt='street' />
        </Flex>
      </Center>
    </Box>
  );
};
