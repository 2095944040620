import { Box, Button, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { GrClose } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { OutlinedButton, PrimaryButton } from '../../../../components/common/CustomButtons';
import { BiPencil } from 'react-icons/bi';
import { PROTECTED_PATHS } from '../../../../app/routes';
import { dynamicPathhandler } from '../../../../utils/utils';
import { useDeleteVehicleMutation, useUpdateAvailabilityMutation } from '../service/listingApi';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';
import { useSelector } from 'react-redux';
import { userRole } from '../../../../redux/features/userSlice';
import { CautionAlertDialog } from '../../../../components/common/CautionAlertDialog';

export const IndividualListing = ({ item, onClose }) => {
  const user = useSelector(userRole);
  const parentPath = dynamicPathhandler(PROTECTED_PATHS.SUPER_ADMIN_EDIT_LISTING);
  const adminParentPath = dynamicPathhandler(PROTECTED_PATHS.ADMIN_EDIT_LISTING);
  const [updateAvailability, { isLoading }] = useUpdateAvailabilityMutation();
  const [deleteVehicle, { isLoading: deleteLoading }] = useDeleteVehicleMutation();

  const UpdadateAvailability = () => {
    updateAvailability(item.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => {
        errorToast();
      });
  };

  const handleDeleteVehicle = () => {
    deleteVehicle(item.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => {
        errorToast();
      });
  };

  return (
    <Stack py='2' px='5'>
      <HStack justify='space-between' p='4' shadow='sm'>
        <Text textAlign='center' flex='1' textStyle='h3' fontSize='20px'>
          Individual Listing
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>

      <Flex p='3' justify='center'>
        <Img objectFit='cover' h='250px' w='100%' src={item?.images[0]?.url || '/images/Bitmap.png'} alt='listing' />
      </Flex>
      <Stack p='5'>
        <Text textStyle='h3'>{item?.name}</Text>
        <HStack as='ul' gap='1rem'>
          <Text>{item?.exterior_color}</Text>
          <Text as='li'>#{item?.ref_id}</Text>
          <Text as='li'>{item?.warranty} Years Warranty</Text>
          <Text as='li'>{Number(item?.Mileage) || 'unknown'}Miles</Text>
        </HStack>
        <Link target='_blank' to='https://www.iubenda.com/privacy-policy/98944637.pdf' style={{ color: 'blue', textDecoration: 'underline', paddingTop: '1rem' }}>
          View Terms and Conditions
        </Link>
        <Text textStyle='h4' marginBottom='1px solid #ededed' pb='1rem'>
          {item?.warranty} Years Warranty
        </Text>
        <Text>Number of ongoing request</Text>
        <Text textStyle='h4' m='0 !important' pb='5'>
          4 Customers
        </Text>
        <HStack gap='1rem' wrap='wrap'>
          <Link to={user === 'super-admin' ? `${parentPath}/${item.id}` : `${adminParentPath}/${item.id}`}>
            <PrimaryButton size='sm' py='5' leftIcon={<BiPencil />}>
              Edit Listing
            </PrimaryButton>
          </Link>
          <Button
            w='150px'
            size='sm'
            py='5'
            isLoading={isLoading}
            onClick={UpdadateAvailability}
            variant='outline'
            borderColor='brand.500'
            color='brand.500'
            // leftIcon={<Checkbox isChecked={!item?.availability} />}
          >
            {!item?.availability ? 'Re-List for sale' : 'Mark as Sold'}
          </Button>

          <CautionAlertDialog
            size='xs'
            button={
              <OutlinedButton isLoading={deleteLoading} py='5' size='sm' color='red'>
                Delete Listing
              </OutlinedButton>
            }
            onContinue={handleDeleteVehicle}
          >
            <Text textAlign='center' textStyle='h3' fontSize={'17px'}>
              Are you sure you want to delete this Vehicle?
            </Text>
          </CautionAlertDialog>
        </HStack>
      </Stack>
    </Stack>
  );
};
