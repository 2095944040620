import { Box, Flex, HStack, Input, Select, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { PageWrapper } from '../../../../components/common/PageWrapper';
import { errorToast, infoToast, successToast } from '../../../../components/common/ToastHandler';
import { useGetUserProfileQuery, useUpdateProfileMutation } from '../../../../redux/features/api/profileApiSlice';
import { disableForObject } from '../../../../utils/utils';
import { SettingHead } from './SettingHead';

export const AccountInformation = () => {
  const { data: profile, isLoading } = useGetUserProfileQuery();
  const [accountValues, setAccountValues] = useState({});
  const [updateProfile, { isLoading: profileUpdateLoading }] = useUpdateProfileMutation();

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    setAccountValues(profile?.data);
  }, [profile?.data]);

  const onChange = (e) => {
    setAccountValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdateProfile = async () => {
    const payload = {
      name: accountValues.name,
      dob: accountValues.dob,
      gender: accountValues.gender,
      address: accountValues.address,
      email: accountValues.email,
      phone_number: accountValues.phone_number,
    };

    const allInputs = disableForObject(payload);

    if (!allInputs) return infoToast('All Fields are required');

    await updateProfile(payload)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => {
        errorToast();
      });
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper>
      <Box maxW='550px' mt='8' mb='12'>
        <SettingHead />
        <Box w='100%' pt='10'>
          <Text>Full Name</Text>
          <Input onChange={onChange} name='name' value={accountValues?.name} />
        </Box>
        <Stack>
          <Box w='100%' pt='5'>
            <Text>Gender</Text>
            <Select name='gender' value={accountValues?.gender} onChange={onChange}>
              <option value='' disabled>
                Select Your Gender
              </option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
              <option value='Other'>Prefer not to say</option>
            </Select>
          </Box>

          <HStack justify='space-between' pt='5'>
            <Box w='100%' pt='5'>
              <Text>Date of birth</Text>
              <Input type='date' max={today} value={accountValues?.dob} name='dob' onChange={onChange} />
            </Box>
          </HStack>

          <Box w='100%' pt='5'>
            <Text>Official email address</Text>
            <Input value={accountValues?.email} name='email' readOnly onChange={onChange} />
          </Box>

          <Box w='100%' pt='5'>
            <Text>Phone number</Text>
            <Input value={accountValues?.phone_number} name='phone_number' onChange={onChange} />
          </Box>

          <Box w='100%' pt='5'>
            <Text>Address</Text>
            <Input value={accountValues?.address} name='address' onChange={onChange} />
          </Box>

          <Flex gap='1rem' py='10'>
            <PrimaryButton w='180px' isLoading={profileUpdateLoading} onClick={handleUpdateProfile}>
              Update Settings
            </PrimaryButton>
            {/* <OutlinedButton w='180px'>Cancel</OutlinedButton> */}
          </Flex>
        </Stack>
      </Box>
    </PageWrapper>
  );
};

export const style = { bg: 'transparent', border: 'none', fontSize: '16px', textStyle: 'h4', w: '100%' };
