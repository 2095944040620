import { Box, Flex, FormControl, FormLabel, HStack, Input, Stack, Text, Textarea } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { errorToast, successToast, warningToast } from '../../../../components/common/ToastHandler';
import { usePublicContactUsMutation } from '../../../../redux/features/api/contactUs';
import { useGetUserProfileQuery } from '../../../../redux/features/api/profileApiSlice';

import { labelStyle } from '../../../../utils/theme';
import { disableForObject } from '../../../../utils/utils';

export const LoggedInUserSendMessage = ({ onClose, message, setMessage, carDetails }) => {
  const { data: userProfile } = useGetUserProfileQuery();
  const [profile, setProfile] = useState({});
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [publicContactUs, { isLoading }] = usePublicContactUsMutation();

  const sendMessage = (e) => {
    e.preventDefault();
    const payload = {
      name: name || profile?.name,
      message,
      email: email || profile?.email,
      phone_number: phoneNumber || profile?.phone_number,
      inventory_id: carDetails?.id.toString(),
    };

    const filled = disableForObject(payload);

    if (!filled) return warningToast('All Fields are required');

    publicContactUs(payload)
      .unwrap()
      .then((data) => {
        successToast(data?.message);
        onClose();
      })
      .catch(() => {
        errorToast();
      });
  };

  useEffect(() => {
    setProfile(userProfile?.data);
    window.scroll(0, 0);
  }, [userProfile?.data]);

  return (
    <Stack py='2' px='6'>
      <HStack justify='space-between' p='4' shadow='sm'>
        <Text textAlign='center' textStyle='h3' fontSize='20px'>
          New Message
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>

      <FormControl py='10' as='form' onSubmit={sendMessage}>
        <Stack spacing='5'>
          <Box>
            <FormLabel {...labelStyle}>Message</FormLabel>
            <Textarea value={message || ''} onChange={(e) => setMessage(e.target.value)} />
          </Box>

          <Box>
            <FormLabel {...labelStyle}>Name</FormLabel>
            <Input value={name || profile?.name || ''} onChange={(e) => setName(e.target.value)} />
          </Box>
          <Box>
            <FormLabel {...labelStyle}>Phone Number</FormLabel>
            <Input type='number' value={phoneNumber || profile?.phone_number || ''} onChange={(e) => setPhoneNumber(e.target.value)} />
          </Box>

          <Box>
            <FormLabel {...labelStyle}>Email</FormLabel>
            <Input type='email' value={email || profile?.email || ''} onChange={(e) => setEmail(e.target.value)} />
          </Box>
        </Stack>
        <Flex pt='10'>
          <PrimaryButton isLoading={isLoading} w='100%' bg='black' type='submit'>
            Send Message
          </PrimaryButton>
        </Flex>
      </FormControl>
    </Stack>
  );
};
