import { GiMoneyStack } from 'react-icons/gi';
import { RiCustomerService2Line } from 'react-icons/ri';
import { TbCertificate } from 'react-icons/tb';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';

export const carLogos = [
  '/images/toyota.png',
  '/images/audi.png',
  '/images/Chevrolet.png',
  // '/images/chrysler.png',
  '/images/honda.png',
  '/images/Hyundai.png',
  '/images/Jaguar.png',
  '/images/tesla.png',
  '/images/Mercedes.png',

  // '/images/ford.png',
  // '/images/Alfa-Romeo.png',
];

export const whyChooseUsTexts = [
  {
    title: 'Certified Car Dealer',
    text: 'We are certified car dealers licensed by the United States of America.',
    Icon: TbCertificate,
  },
  {
    title: 'Best Price',
    text: "Looking for a car that matches your purpose? Don't fret, we'll have a discovery interaction with you to help secure the best car that suits your needs.",
    Icon: GiMoneyStack,
  },
  {
    title: 'Transparency',
    text: 'Sadly, today, there are tons of dealers who sell stolen cars. However, with us, you can be sure of secured cars with detailed history reports.',
    Icon: BsFillCreditCard2FrontFill,
  },
  {
    title: 'Customer Service',
    text: 'We offer unbeatable customer service.',
    Icon: RiCustomerService2Line,
  },
  {
    title: 'Certified Car Dealer',
    text: 'We are certified car dealers licensed by the United States of America.',
    Icon: TbCertificate,
  },
  {
    title: 'Best Price',
    text: "Looking for a car that matches your purpose? Don't fret, we'll have a discovery interaction with you to help secure the best car that suits your needs.",
    Icon: GiMoneyStack,
  },
  {
    title: 'Transparency',
    text: 'Sadly, today, there are tons of dealers who sell stolen cars. However, with us, you can be sure of secured cars with detailed history reports.',
    Icon: BsFillCreditCard2FrontFill,
  },
  {
    title: 'Customer Service',
    text: 'We offer unbeatable customer service.',
    Icon: RiCustomerService2Line,
  },
];

export const priceRange = [
  {
    label: 'Below $1,000',
    value: '0-1000',
  },
  {
    label: '$1,000 - $10,000',
    value: '1000-10000',
  },
  {
    label: '$10,000 - $20,000',
    value: '11000-20000',
  },
  {
    label: '$20,000-$30,000',
    value: '21000-30000',
  },
  {
    label: '$30,000-$40,000',
    value: '31000-40000',
  },
  {
    label: '$40,000-$50,000',
    value: '40000-50000',
  },
  {
    label: '$50,000-$60,000',
    value: '50000-60000',
  },
  {
    label: '$60,000-$70,000',
    value: 1000,
  },
  {
    label: '$70,000-$80,000',
    value: 1000,
  },
  {
    label: '$80,000-$90,000',
    value: 1000,
  },
  {
    label: '$90,000-$100,000',
    value: 1000,
  },
  {
    label: 'Above $100,000',
    value: 1000,
  },
];

export function getYearRanges(startYear, endYear, r) {
  const yearRanges = [];
  let rangeStart = startYear;
  while (rangeStart <= endYear) {
    const rangeEnd = Math.min(rangeStart + r - 1, endYear);
    const yearRange = `${rangeStart}-${rangeEnd}`;
    yearRanges.push(yearRange);
    rangeStart += r;
  }
  return yearRanges;
}
