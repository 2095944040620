import { Button, Flex, HStack, Img, List, ListItem, Square } from '@chakra-ui/react';
import { Link, NavLink } from 'react-router-dom';
import { RiUser3Fill } from 'react-icons/ri';
import { PageWrapper } from './PageWrapper';
import { homeNavLinks } from './extras';
import { nanoid } from '@reduxjs/toolkit';
import { PUBLIC_PATHS } from '../../app/routes';

export const HomePageHeader = () => {
  return (
    <PageWrapper>
      <Flex as='nav' justify='space-between' align='center' wrap='wrap' pb={['5', '5', 0]}>
        <Link to={PUBLIC_PATHS.HOME}>
          <Img pos='relative' left='-20px' src='/images/logo.svg' alt='gabe' />
        </Link>

        <Flex as={List} gap={['1rem', '2rem', '2.5rem']} order={[2, 2, 0]}>
          {homeNavLinks?.map((link) => (
            <ListItem fontWeight='semibold' w='fit-content' key={nanoid()}>
              <NavLink to={link?.to ? link.to : '/'} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                {link?.title}
              </NavLink>
            </ListItem>
          ))}
        </Flex>

        {/* <Link to={PUBLIC_PATHS.LOGIN}> */}
        <HStack as={Link} to={PUBLIC_PATHS.LOGIN} order={[1, 1, 0]}>
          <Square borderRadius='5px' bg='brand.200' p='1'>
            <RiUser3Fill />
          </Square>
          <Button variant='link'>Sign in</Button>
        </HStack>
        {/* </Link> */}
      </Flex>
    </PageWrapper>
  );
};

export const activeStyle = { color: '#731012', paddingBottom: '4px', borderBottom: '2px solid #333' };

export const mobileActiveStyle = { color: '#731012', paddingBottom: '4px', borderBottom: '2px solid #333' };
