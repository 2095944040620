import { Box, Center, Flex, HStack, Img, Stack, Text, VStack } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { formatInThousands } from '../../../../utils/utils';
import { fallBackImg } from '../../../../components/common/extras';
import { useGetWindowSize } from '../../../../hooks/useGetWindowSize';
import { useEffect, useState } from 'react';
import { useGetAllCarMakesQuery } from './service';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { useSelector } from 'react-redux';
import { userRole } from '../../../../redux/features/userSlice';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../../app/routes';

export const FindCarByModel = () => {
  const { data: allCarMakes, isLoading } = useGetAllCarMakesQuery();
  const windowSize = useGetWindowSize();
  const [numberOfSlidesToRender, setNumberOfSlidesToRender] = useState(1);

  useEffect(() => {
    if (windowSize <= 600) {
      setNumberOfSlidesToRender(1);
    } else if (windowSize < 700) {
      setNumberOfSlidesToRender(2);
    } else if (windowSize < 1200) {
      setNumberOfSlidesToRender(3);
    } else {
      setNumberOfSlidesToRender(4);
    }
  }, [windowSize]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack as='section'>
      <CarouselProvider
        infinite={true}
        visibleSlides={numberOfSlidesToRender + 0.1}
        //   dragEnabled={true}
        isPlaying={false}
        totalSlides={allCarMakes?.data?.length}
        //   naturalSlideWidth={200}
        //   naturalSlideHeight={480}
        isIntrinsicHeight={true}
      >
        <Stack as='section'>
          <HStack justify='space-between' py='5'>
            <Text textStyle='h3' py='5'>
              Find Car By Make
            </Text>
            <HStack gap='10px'>
              <Text mr='3'>
                {numberOfSlidesToRender}/{allCarMakes?.data?.length}
              </Text>
              <ButtonBack>
                <Center p='2' bg='#d5d5d5' borderRadius='5px' cursor='pointer'>
                  <BsArrowLeft size={16} color='black' />
                </Center>
              </ButtonBack>

              <ButtonNext>
                <Center p='2' bg='#d5d5d5' borderRadius='5px' cursor='pointer'>
                  <BsArrowRight size={16} color='black' />
                </Center>
              </ButtonNext>
            </HStack>
          </HStack>

          <Box>
            <Slider className='slider'>
              <Flex gap='3rem'>
                {allCarMakes?.data?.map((car, index) => (
                  <Slide index={(e) => console.log(e)} className='slide' key={nanoid()}>
                    <CarByModelCar index={index} car={car} />
                  </Slide>
                ))}
              </Flex>
            </Slider>
          </Box>
        </Stack>
      </CarouselProvider>
    </Stack>
  );
};

const CarByModelCar = ({ car }) => {
  const user = useSelector(userRole);
  const navigate = useNavigate();

  const handleNavigate = (car) => {
    user === 'user' ? navigate(PROTECTED_PATHS.INVENTORY, { state: { car } }) : navigate(PUBLIC_PATHS.INVENTORY, { state: { car } });
  };

  return (
    <VStack className='car__by__model_contianer' w='270px' cursor='pointer' onClick={() => handleNavigate(car)}>
      <Img loading='lazy' fallback={fallBackImg} w='260px' h='130px' borderTopRadius='3px' objectFit='cover' src='/images/Bitmap.png' alt='trending car' />
      <Text textStyle='h4' pt='2'>
        {car?.name}
      </Text>
      <Text textAlign='center' fontSize='12px' w='140px' py='2' px='2' fontWeight='700' borderRadius='4px' bg='rgba(232, 232, 232,0.8)'>
        {formatInThousands(car?.inventory_count)} vehicles
      </Text>
    </VStack>
  );
};
