import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PROTECTED_PATHS, PUBLIC_PATHS } from './routes';

export const PublicApp = ({ user }) => {
  const location = useLocation();

  const pageTogo = () => {
    switch (user) {
      case 'admin':
        return PROTECTED_PATHS.ADMIN_LISTING;

      case 'user':
        return PROTECTED_PATHS.HOME;

      case 'super-admin':
        return PROTECTED_PATHS.SUPER_ADMIN_MANAGE_ADMINS;

      default:
        return PUBLIC_PATHS.HOME;
    }
  };

  return !user ? <Outlet /> : <Navigate to={pageTogo()} state={{ from: location }} replace />;
};
