import { HStack, Text } from '@chakra-ui/react';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { border } from '../../../utils/theme';
import { MessagesTable } from './components/MessagesTable';

export const Messages = () => {
  return (
    <PageWrapper>
      <HStack border={border} p='3' borderTopRadius='5px' justify='space-between'>
        <HStack gap='1.5rem'>
          <Text textStyle='h3' fontSize='17px'>
            All Enquiries
          </Text>
        </HStack>
      </HStack>
      <MessagesTable />
    </PageWrapper>
  );
};
