import { Center, FormControl, FormLabel, Img, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthPagesBody } from '.';
import { PUBLIC_PATHS } from '../../app/routes';
import { PrimaryButton } from '../../components/common/CustomButtons';
import { errorToast, successToast } from '../../components/common/ToastHandler';
import { useForgotPasswordMutation } from './service';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    await forgotPassword({ email })
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast());
  };
  return (
    <AuthPagesBody>
      <Center minH='75vh' flexDirection='column'>
        <FormControl as={'form'} maxW='400px' mx='auto' onSubmit={handleForgotPassword}>
          <Img src='/images/Lock.png' alt='Lock' />
          <Text textStyle='h3' fontSize='2rem'>
            Lost Your Password?
          </Text>
          <Text fontSize='1rem' mb='2rem'>
            Provide your previously registered email to retrieve your password.
          </Text>
          <FormLabel m='0' htmlFor='email'>
            Email
          </FormLabel>
          <Input type='email' required mb='10' bg='brand.100' placeholder='gbemiadeniran@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />

          <PrimaryButton isLoading={isLoading} type='submit' w='full'>
            Recover Password
          </PrimaryButton>

          <Text textAlign='center' py='5'>
            Already have an account?{' '}
            <Link to={PUBLIC_PATHS.LOGIN} style={{ fontWeight: 'bold' }}>
              Login
            </Link>
          </Text>
        </FormControl>
      </Center>
    </AuthPagesBody>
  );
};
