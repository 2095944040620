import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINTS } from '../../../services/routes.constants';

export const profileApiSlice = createApi({
  reducerPath: 'profileApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['profileApiSlice'],

  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => API_ENDPOINTS.GET_USER_PROFILE,
      providesTags: ['profileApiSlice'],
    }),

    updatePhoneNumber: builder.mutation({
      query: (body) => ({
        method: 'PUT',
        url: API_ENDPOINTS.UPDATE_PHONE_NUMBER,
        body,
      }),
      invalidatesTags: ['profileApiSlice'],
    }),

    updatePassword: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.UPDATE_PASSWORD,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['profileApiSlice'],
    }),

    uploadProfilePhoto: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.UPLOAD_PRIFILE_PHOTO,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-headers': 'Content-Type',
        },
      }),
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.UPDATE_PROFILE,
        method: 'PUT',
        body,
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-headers': 'Content-Type',
        },
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdatePhoneNumberMutation,
  useUpdatePasswordMutation,
  useUploadProfilePhotoMutation,
  useUpdateProfileMutation,
} = profileApiSlice;
