export const API_ENDPOINTS = {
  SIGN_UP: '/register',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  GET_NEW_OTP: '/resent_otp',
  VERIY_EMAIL: '/verify_email',
  RESET_PASSWORD: '/reset_password',

  //invetory
  GET_A_PARTICULAR_MODEL: (makeId) => `/models/${makeId}`,
  GET_MAKE_MODELS: (makeId) => `/makes/${makeId}/model`,
  GET_MAKE: '/makes',
  GET_ALL_CAR_MODELS: '/models',
  GET_CAR_BY_CATEGORY: (category = {}) => `/inventories?category=${category}`,
  ALL_CARS_INVENTORIES: '/inventories',
  GET_CAR_FULL_DETAILS: (carId) => `/inventories/${carId}`,
  DELETE_VEHICLE: (carId) => `/inventories/${carId}`,
  UPDATE_VEHICLE: (id) => `/inventories/${id}`,
  MARK_VEHICLE_AS_FAVOURITE: '/inventories/favorite',
  GET_USER_FAVOURITES: '/inventories/my-favorite',

  //profile
  GET_USER_PROFILE: '/profile',
  UPDATE_AVAILABILITY: (id) => `/inventories/${id}/availability`,
  UPDATE_PHONE_NUMBER: '/profile/update-phone',
  UPDATE_PASSWORD: '/profile/update-password',
  UPLOAD_PRIFILE_PHOTO: '/profile/update-profile-image',
  UPDATE_PROFILE: '/profile/update-profile',

  //filter
  FILTER_SEARCH: ({ name, year, price, make, extColor, intColor, transmission }) =>
    `/search?filter[name]=${name}&filter[year]=${year}&filter[price]=${price}&filter[make]=${make}&filter[exterior_color]=${extColor}&filter[interior_color]=${intColor}&filter[transmission]=${transmission}`,

  //super admin
  SUPER_ADMIN_ADD_ADMIN: '/superadmin/add-admin',
  SUPER_ADMIN_GET_USERS_BY_ROLE: (role) => `/superadmin/users/${role}`,
  DISABLE_USER_ACCOUNT: (userId) => `/superadmin/${userId}/block-user`,
  SUPER_ADMIN_BLOCK_USER: (userId) => `/superadmin/${userId}/block-user`,
  DELETE_USER: (userId) => `/superadmin/${userId}/delete-user`,
  REACTIVATE_USER: (id) => `/superadmin/${id}/unblock-user`,

  //contact us
  CONTACT_US: '/contacts',
  DELETE_CONTACT: (contactId) => `/contacts/${contactId}`,

  //messages
  GET_ALL_MESSAGES: '/contacts',
  DELETE_MESSAGE: (messageId) => `/contacts/${messageId}`,
  UPDATE_MARK_AS_READ: (id) => `/contacts/${id}/update-status`,

  //subscription
  GET_ALL_SUBSCRIBERS: '/subscriptions',
};
