import {
  Avatar,
  Button,
  Flex,
  HStack,
  Img,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { loggedInNavLinks } from './extras';
import { activeStyle } from './HomePageHeader';
import { PageWrapper } from './PageWrapper';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/userSlice';
import { PROTECTED_PATHS } from '../../app/routes';
import { useGetUserProfileQuery } from '../../redux/features/api/profileApiSlice';

export const LoggedInUserHeader = () => {
  const { data: userProfile } = useGetUserProfileQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('persist:#gbe#xp&st');
    window.location.reload(false);
  };

  return (
    <PageWrapper>
      <Flex as='nav' justify='space-between' align='center' wrap='wrap'>
        <Link to={PROTECTED_PATHS.HOME}>
          <Img
            w={['100px', '120px']}
            pos='relative'
            left='-20px'
            src='/images/logo.svg'
            alt='gabe'
          />
        </Link>

        <Flex as={List} gap={['1rem', '1rem', '2rem']} order={[3, 3, 3, 0]}>
          {loggedInNavLinks?.map((link) => (
            <ListItem fontSize={['13px']} fontWeight='semibold' key={nanoid()}>
              <NavLink
                to={link?.to ? link.to : '/'}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {link?.title}
              </NavLink>
            </ListItem>
          ))}
        </Flex>

        <HStack gap='1rem'>
          {/* <Square borderRadius='5px' bg='whiteAlpha.700' p='2'>
            <VscBellDot size={22} />
          </Square> */}

          <Menu>
            <MenuButton
              w='190px'
              bg='white'
              as={Button}
              leftIcon={
                <Avatar
                  size='sm'
                  src={userProfile?.data?.profile_image}
                  name={userProfile?.data?.name}
                />
              }
            >
              {userProfile?.data?.name}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate(PROTECTED_PATHS.PROFILE_SETTINGS)}>
                Account Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </PageWrapper>
  );
};
