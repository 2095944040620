import { Box, Center, Flex, HStack, Stack, Text, Textarea } from '@chakra-ui/react';
import { FaCar } from 'react-icons/fa';
import { MdAirlineSeatReclineNormal, MdLocalGasStation } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, formatInThousands } from '../../../../utils/utils';
import CustomModal from '../../../../components/common/CustomModal';
import { LoggedInUserSendMessage } from './LoggedInUserSendMessage';
import { useState } from 'react';

export const DescriptionAndPrice = ({ carDetails }) => {
  const [message, setMessage] = useState('');
  return (
    <Flex gap='3rem' direction={['column', 'column', 'column', 'row']}>
      <Stack flex='7' py='2rem' pt={['', '4rem', 0]}>
        <Text textStyle='h3' fontSize='2rem'>
          Description
        </Text>
        <Text pb='4rem'>{carDetails?.description}</Text>

        <Text textStyle='h3' fontSize='2rem' pt='5rem' borderTop='2px solid #ededed'>
          Features
        </Text>

        <Stack columns={2} as={Stack} spacing='5'>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Model</Text>
            <Text textStyle='h4'>{carDetails?.model?.name}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Type</Text>
            <Text textStyle='h4'>{capitalizeFirstLetter(carDetails?.body_style)}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Door</Text>
            <Text textStyle='h4'>{carDetails?.door}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Drive</Text>
            <Text textStyle='h4'>{capitalizeFirstLetter(carDetails?.drive_train)}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Fuel Type</Text>
            <Text textStyle='h4'>{carDetails?.fuel_type === 'undefined' ? 'Regular' : carDetails?.fuel_type}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Interior Color</Text>
            <Text textStyle='h4'>{capitalizeFirstLetter(carDetails?.interior_color)}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Exterior Color</Text>
            <Text textStyle='h4'>{capitalizeFirstLetter(carDetails?.exterior_color)}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>Seat</Text>
            <Text textStyle='h4'>{carDetails?.seat}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>transmission</Text>
            <Text textStyle='h4'>{capitalizeFirstLetter(carDetails?.transmission)}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>vin</Text>
            <Text textStyle='h4'>{carDetails?.vin}</Text>
          </HStack>
          <HStack justify='space-between' pb='2' borderBottom={'1px solid #ececec'}>
            <Text>year</Text>
            <Text textStyle='h4'>{carDetails?.year}</Text>
          </HStack>
        </Stack>
      </Stack>

      <Stack flex='3.2' bg='white' color='#9A9EA7' p='2.5rem' borderRadius='5px'>
        <Text textStyle='h3' fontSize='1.5rem'>
          Price - ${formatInThousands(carDetails?.price)}
        </Text>
        <Text># {carDetails?.ref_id}</Text>
        <Text pt='5'>Features</Text>
        <Stack spacing='5'>
          <HStack>
            <Center bg='blackAlpha.100' p='2' borderRadius='6px'>
              <FaCar size={22} />
            </Center>
            <Text textStyle='h4' fontSize='16px'>
              {carDetails?.Mileage === 'undefined' ? 'Unknown' : carDetails?.Mileage} miles
            </Text>
          </HStack>
          <HStack>
            <Center bg='blackAlpha.100' p='2' borderRadius='6px'>
              <MdAirlineSeatReclineNormal size={22} />
            </Center>
            <Text textStyle='h4' fontSize='16px'>
              {carDetails?.seat} Seats
            </Text>
          </HStack>
          <HStack>
            <Center bg='blackAlpha.100' p='2' borderRadius='6px'>
              <MdLocalGasStation size={22} />
            </Center>
            <Text textStyle='h4' fontSize='16px'>
              {carDetails?.interior_color} & {carDetails?.exterior_color} colors
            </Text>
          </HStack>
          <Text>
            Warranty: {carDetails?.warranty === 'no warranty' ? 'No waranty' : carDetails.warranty === '1' ? '1yr waranty' : `${carDetails?.warranty}yrs warranty`}
          </Text>

          <Box>
            <Text textStyle='h4' color='brand.500' pt='4'>
              <Link target='_blank' to='https://www.iubenda.com/privacy-policy/98944637.pdf'>
                View Terms and Condition
              </Link>
            </Text>
          </Box>

          <Stack pt='5'>
            <Text textStyle='h4'>Send message</Text>
            <Textarea rows='5' resize='none' message={message} onChange={(e) => setMessage(e.target.value)} />
            <Flex py='5'>
              <CustomModal buttonProps={{ size: 'lg', w: '100%', bg: 'brand.500' }} btnTitle='Send Message'>
                <LoggedInUserSendMessage carDetails={carDetails} message={message} setMessage={setMessage} />
              </CustomModal>
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
