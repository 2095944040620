import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PUBLIC_PATHS } from '../../../app/routes';
import { errorToast, infoToast, successToast } from '../../../components/common/ToastHandler';
import { loginStart, loginSuccess } from '../../../redux/features/userSlice';
import http, { API_ENDPOINTS, headers } from '../../../services/api';

export const login = async (dispatch, payload, setLoading, navigate) => {
  dispatch(loginStart());
  setLoading(true);
  try {
    const { data } = await http.post(API_ENDPOINTS.LOGIN, payload);

    await dispatch(loginSuccess(data));
    successToast(data.message);
    setLoading(false);
  } catch (err) {
    if (err.response.status === 403) {
      infoToast('Account not verified. Please Verify');
      localStorage.setItem('unverifiedUserInfo', JSON.stringify(err.response.data));
      navigate(PUBLIC_PATHS.VERIFY_EMAIL);
      setLoading(false);
      return;
    } else {
      errorToast(err.response.data.message);
      setLoading(false);
      return;
    }
  }
};

export const authenticationApiSlice = createApi({
  reducerPath: 'authenticationApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // const token = getState().persistedReducer;
      // console.log({ token });
      // token && headers.set('authorization', `Bearer ${token}`);
      // return headers;
    },
  }),
  tagTypes: ['Authentication'],

  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.SIGN_UP,
        method: 'POST',
        body,
        headers: headers,
      }),
      inavalidateTags: ['Authentication'],
    }),

    forgotPassword: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.FORGOT_PASSWORD,
        method: 'POST',
        body,
        headers: headers,
      }),
      inavalidateTags: ['Authentication'],
    }),

    getNewOTP: builder.mutation({
      query: (token) => ({
        url: API_ENDPOINTS.GET_NEW_OTP,
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
      inavalidateTags: ['Authentication'],
    }),

    verifyEmail: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: API_ENDPOINTS.VERIY_EMAIL,
        body: { otp: body.OTP },
        headers: {
          authorization: `Bearer ${body.registeredUserToken}`,
        },
      }),
      inavalidateTags: ['Authentication'],
    }),

    resetPassword: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: API_ENDPOINTS.RESET_PASSWORD,
        body,
      }),
    }),

    // login: builder.mutation({
    //   query: (body) => ({
    //     // hhhh: console.log('ggg', body),
    //     url: API_ENDPOINTS.LOGIN,
    //     method: 'POST',
    //     body,
    //   }),
    // inavalidateTags: ['Authentication'],
    // }),
  }),
});

export const { useSignUpMutation, useForgotPasswordMutation, useGetNewOTPMutation, useVerifyEmailMutation, useResetPasswordMutation } = authenticationApiSlice;
