import { Stack, Text, VStack, Box, Flex } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { useEffect, useState } from 'react';
import { useGetWindowSize } from '../../../../hooks/useGetWindowSize';
import { SingleWhyChooseUsCard } from './SingleWhyChooseUsCard';
import { whyChooseUsTexts } from './extras';

export const WhyChooseUs = () => {
  const windowSize = useGetWindowSize();
  const [numberOfSlidesToRender, setNumberOfSlidesToRender] = useState(1);

  useEffect(() => {
    if (windowSize <= 540) {
      setNumberOfSlidesToRender(1);
    } else if (windowSize < 780) {
      setNumberOfSlidesToRender(2);
    } else if (windowSize < 1200) {
      setNumberOfSlidesToRender(3);
    } else {
      setNumberOfSlidesToRender(4);
    }
  }, [windowSize]);

  return (
    <Stack py='28' as='section'>
      <VStack mb='5'>
        <Text textStyle='h2'>Why Choose Us</Text>
        {/* <Text textAlign='center' fontSize='15px' maxW='500px'>
          Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam eget ex sed velit pulvinar auctor curabitur.
        </Text> */}
      </VStack>
      <Box>
        <CarouselProvider
          infinite={true}
          visibleSlides={numberOfSlidesToRender}
          dragEnabled={true}
          isPlaying={true}
          naturalSlideWidth={200}
          naturalSlideHeight={370}
          totalSlides={whyChooseUsTexts?.length}
          isIntrinsicHeight={true}
          // naturalSlideHeight={numberOfSlidesToRender === 1 ? 130 : 180}
        >
          <Slider>
            <Flex gap='5'>
              {whyChooseUsTexts?.map((why) => (
                <Slide className='slide' key={nanoid()}>
                  <SingleWhyChooseUsCard {...why} />
                </Slide>
              ))}
            </Flex>
          </Slider>
        </CarouselProvider>
      </Box>
    </Stack>
  );
};
