import { useState } from 'react';
import CustomModal from '../../../../components/common/CustomModal';
import { BsThreeDots } from 'react-icons/bs';
import { CustomerDetailsView } from './CustomerDetailsView';
import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Paginator } from '../../../../components/common/Paginator';
import { formatDate } from '../../../../utils/utils';
import { useGetUserByRoleQuery } from '../../../../redux/features/api/superAdminApilSlice';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';

export const CustomersTable = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const { data: customers, isLoading } = useGetUserByRoleQuery('user');

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  const displayedCustomers = customers?.slice(pagesVisited, pagesVisited + itemsPerPage);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <TableContainer py='10' bg='white' border='1px solid #ededed' borderRadius='5px'>
        <Table variant='Unstyled' size='lg'>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Full Name</Th>
              <Th>Email Address</Th>
              {/* <Th>Status</Th> */}
            </Tr>
          </Thead>
          <Tbody fontSize='14px'>
            {displayedCustomers?.map((customer, i) => (
              <Tr key={nanoid()}>
                <Td>{formatDate(customer?.created_at)}</Td>
                <Td>{customer?.name}</Td>
                <Td>{customer?.email}</Td>
                <Td>
                  <CustomModal size='xl' btnTitle={<BsThreeDots color='black' size={25} />}>
                    <CustomerDetailsView customer={customer} />
                  </CustomModal>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex justify='center'>
          <Paginator data={customers} itemsPerPage={itemsPerPage} setPageNumber={setPageNumber} />
        </Flex>
      </TableContainer>
    </Box>
  );
};
