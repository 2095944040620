import { Box, Center, Circle, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../app/routes';

export const AuthPagesBody = ({ children }) => {
  return (
    <Flex minH='100vh' w='100vw'>
      <Stack flex='1.2' px='5%'>
        <Link to={PUBLIC_PATHS.HOME}>
          <Img w={['auto']} h='100px' src='/images/logo.svg' alt='logo' />
        </Link>
        <Box minH='calc(100vh - 100px)'>{children}</Box>
      </Stack>
      <Box flex='1' bg='brand.200' px='5%' display={['none', 'none', 'block']}>
        <Center minH='75vh'>
          <Box>
            <Img h='400px' src='/images/auth-car.png' alt='car' />{' '}
          </Box>
        </Center>
        <Stack>
          <HStack>
            <Text>Powered by</Text>
            <Circle bg='#fff' p='1'>
              <Img w='20px' h='20px' src='/images/logo.svg' alt='logo' />
            </Circle>
          </HStack>
          <Box maxW='400px'>
            {' '}
            You agree to Gabe Investments{' '}
            <Text _hover={{ textDecoration: 'underline' }} as={Link} to='#'>
              Terms of Use
            </Text>{' '}
            &{' '}
            <Text
              _hover={{ textDecoration: 'underline' }}
              as={Link}
              target='_blank'
              to='https://us.docs.wps.com/module/common/loadPlatform/?utm_source=wps_office_mac&utm_medium=comp_border_bar&utm_content=link&sid=cIIO_tPomu-akqgY&v=v2'
            >
              Privacy Policy.
            </Text>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
};
