import { Img } from '@chakra-ui/react';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../app/routes';

export const homeNavLinks = [
  { title: 'Home', to: PUBLIC_PATHS.HOME },
  { title: 'Inventory', to: PUBLIC_PATHS.INVENTORY },
  { title: 'Contact Us', to: PUBLIC_PATHS.CONTACT_US },
  { title: 'About Us', to: PUBLIC_PATHS.ABOUT_US },
];

export const loggedInNavLinks = [
  { title: 'Home', to: PROTECTED_PATHS.HOME },
  { title: 'Favorites', to: PROTECTED_PATHS.FAVOURITES },
  { title: 'Inventory', to: PROTECTED_PATHS.INVENTORY },
  // { title: 'Messages', to: PROTECTED_PATHS.MESSAGES },
  { title: 'Contact us', to: PROTECTED_PATHS.CONTACT_US },
  { title: 'About Us', to: PROTECTED_PATHS.ABOUT_US },
];

export const adminnNavLinks = [
  { title: 'Listing', to: PROTECTED_PATHS.ADMIN_LISTING },
  { title: 'Customers', to: PROTECTED_PATHS.ADMIN_CUSTOMERS },
  { title: 'Messages', to: PROTECTED_PATHS.ADMIN_MESSEGES },
  { title: 'Subscribers', to: PROTECTED_PATHS.ADMIN_SUBSCRIBERS },
];

export const adminMobileNavLinks = [
  { title: 'Listing', to: PROTECTED_PATHS.ADMIN_LISTING },
  { title: 'Customers', to: PROTECTED_PATHS.ADMIN_CUSTOMERS },
  { title: 'Messages', to: PROTECTED_PATHS.ADMIN_MESSEGES },
  { title: 'Subscribers', to: PROTECTED_PATHS.ADMIN_SUBSCRIBERS },
  { title: 'Account Settings', to: PROTECTED_PATHS.ADMIN_PROFILE },
];

export const superAdminNavLinks = [
  { title: 'Manage Admins', to: PROTECTED_PATHS.SUPER_ADMIN_MANAGE_ADMINS },
  { title: 'Customers', to: PROTECTED_PATHS.SUPER_ADMIN_CUSTOMERS },
  { title: 'Listing', to: PROTECTED_PATHS.SUPER_ADMIN_LISTING },
  { title: 'Messages', to: PROTECTED_PATHS.SUPER_ADMIN_MESSEGES },
  { title: 'Subscribers', to: PROTECTED_PATHS.SUPER_ADMIN_SUBSCRIBERS },
];

export const mobileSuperAdminNavLinks = [
  { title: 'Manage Admins', to: PROTECTED_PATHS.SUPER_ADMIN_MANAGE_ADMINS },
  { title: 'Customers', to: PROTECTED_PATHS.SUPER_ADMIN_CUSTOMERS },
  { title: 'Listing', to: PROTECTED_PATHS.SUPER_ADMIN_LISTING },
  { title: 'Messages', to: PROTECTED_PATHS.SUPER_ADMIN_MESSEGES },
  { title: 'Subscribers', to: PROTECTED_PATHS.SUPER_ADMIN_SUBSCRIBERS },
  { title: 'Account Settings', to: PROTECTED_PATHS.SUPER_ADMIN_PROFILE },
  { title: 'Add New Listing', to: PROTECTED_PATHS.SUPER_ADMIN_NEW_LISTING },
];

export const fallBackImg = <Img w='300px' h='180px' borderTopRadius='3px' objectFit='cover' src='/images/car.png' alt='trending car' />;
