import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { Layout } from './components/layout/Layout';
import { SuperAdminProtectedApp } from './app/SuperAdminProtectedApp';
import { ManageAdmins } from './pages/super-admin';
import { AdminProtectedApp } from './app/AdminProtectedApp';
import { Messages } from './pages/admin/messages';
import { CustomerProtectedApp } from './app/CustomerProtectedApp';
import { PublicApp } from './app/PublicApp';
import { HomePage } from './pages/public-pages/home';
import { PROTECTED_PATHS, PUBLIC_PATHS } from './app/routes';
import { useDispatch, useSelector } from 'react-redux';
import { InventoryPage } from './pages/public-pages/inventory';
import { ContactUsPage } from './pages/public-pages/contact-us';
import { SupportPage } from './pages/public-pages/support';
import { PublicLayout } from './components/layout/PublicLayout';
import { FullVehicleDetails } from './pages/public-pages/full-vehicle-Details';
import { LoggedInCustomerLayout } from './components/layout/LoggedInCustomerLayout';
import { Favourites } from './pages/customer/favourites/Favourites';
import { CustomerMessages } from './pages/customer/messages';
import { AdminLayout } from './components/layout/AdminLayout';
import { Customers } from './pages/admin/customers';
import { Listings } from './pages/admin/listings';
import { AddNewListingLayout } from './components/layout/AddNewListingLayout';
import { AddNewVehicle } from './pages/admin/add-new-vehicle';
import { ProfileSettings } from './pages/admin/profile-settings';
import { AccountInformation } from './pages/admin/profile-settings/components/AccountInformation';
import { NotificationSettings } from './pages/admin/profile-settings/components/NotificationSettings';
import { LoginDetailsSettings } from './pages/admin/profile-settings/components/LoginDetailsSettings';
import { AdminInformation } from './pages/admin/profile-settings/components/AdminInformation';
import { ListingCreated } from './pages/admin/add-new-vehicle/components/ListingCreated';
import { SuperAdminLayout } from './components/layout/SuperAdminLayout';
import { Login } from './pages/auth-pages/Login';
import { Register } from './pages/auth-pages/Register';
import { ForgotPassword } from './pages/auth-pages/ForgotPassword';
import { logout } from './redux/features/userSlice';
import { useCallback, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { EditListing } from './pages/admin/listings/components/EditListing';
import { VerifyEmail } from './pages/auth-pages/VerifyEmail';
import { AboutUs } from './pages/public-pages/about-us';
import { SetNewPassword } from './pages/auth-pages/SetNewPassword';
import { Subscribers } from './pages/super-admin/subscribers';

function App() {
  // const { user } = useSelector((state) => '' || state.persistedReducer.user);
  // const user = '';
  const user = useSelector((state) => state?.persistedReducer?.user?.user?.data?.role);
  const dispatch = useDispatch();

  const deleteTokenAndKickUserOut = useCallback(() => {
    dispatch(logout());
    localStorage.removeItem('persist:#gbe#xp&st');
    window.location.reload(false);
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem('persist:#gbe#xp&st') ? JSON.parse(JSON.parse(localStorage.getItem('persist:#gbe#xp&st'))?.user)?.user?.data?.token : null;

    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded.exp * 1000);
      new Date() > expiryDate && deleteTokenAndKickUserOut();
    }
  }, [deleteTokenAndKickUserOut]);

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route element={<SuperAdminProtectedApp user={user} />}>
          <Route element={<SuperAdminLayout />}>
            <Route index path={PROTECTED_PATHS.SUPER_ADMIN_LISTING} element={<Listings />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_CUSTOMERS} element={<Customers />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_MESSEGES} element={<Messages />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_PROFILE} element={<ProfileSettings />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_PROFILE_SETTINGS_ACCOUNT} element={<AccountInformation />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_LOGIN_DETAILS_SETTINGS} element={<LoginDetailsSettings />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_ACCOUNT_INFORMATION} element={<AdminInformation />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_MANAGE_ADMINS} element={<ManageAdmins />} />
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_SUBSCRIBERS} element={<Subscribers />} />
          </Route>
          <Route element={<AddNewListingLayout />}>
            <Route path={PROTECTED_PATHS.SUPER_ADMIN_NEW_LISTING} element={<AddNewVehicle />} />
          </Route>
          <Route path={PROTECTED_PATHS.SUPER_ADMIN_EDIT_LISTING} element={<EditListing />} />
          <Route path={PROTECTED_PATHS.SUPER_LISTING_CREATED} element={<ListingCreated />} />
        </Route>

        <Route element={<AdminProtectedApp user={user} />}>
          <Route element={<AdminLayout />}>
            <Route index path={PROTECTED_PATHS.ADMIN_LISTING} element={<Listings />} />
            <Route path={PROTECTED_PATHS.ADMIN_CUSTOMERS} element={<Customers />} />
            <Route path={PROTECTED_PATHS.ADMIN_MESSEGES} element={<Messages />} />
            <Route path={PROTECTED_PATHS.ADMIN_PROFILE} element={<ProfileSettings />} />
            <Route path={PROTECTED_PATHS.ADMIN_PROFILE_SETTINGS_ACCOUNT} element={<AccountInformation />} />
            <Route path={PROTECTED_PATHS.ADMIN_NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
            <Route path={PROTECTED_PATHS.ADMIN_LOGIN_DETAILS_SETTINGS} element={<LoginDetailsSettings />} />
            <Route path={PROTECTED_PATHS.ADMIN_ACCOUNT_INFORMATION} element={<AdminInformation />} />
            <Route path={PROTECTED_PATHS.ADMIN_SUBSCRIBERS} element={<Subscribers />} />
          </Route>
          <Route element={<AddNewListingLayout />}>
            <Route path={PROTECTED_PATHS.ADMIN_NEW_LISTING} element={<AddNewVehicle />} />
          </Route>
          <Route path={PROTECTED_PATHS.ADMIN_EDIT_LISTING} element={<EditListing />} />
          <Route path={PROTECTED_PATHS.LISTING_CREATED} element={<ListingCreated />} />
        </Route>

        <Route element={<CustomerProtectedApp user={user} />}>
          <Route element={<LoggedInCustomerLayout />}>
            <Route index path={PROTECTED_PATHS.HOME} element={<HomePage />} />
            <Route path={PROTECTED_PATHS.FAVOURITES} element={<Favourites />} />
            <Route path={PROTECTED_PATHS.INVENTORY} element={<InventoryPage />} />
            <Route path={PROTECTED_PATHS.MESSAGES} element={<CustomerMessages />} />
            <Route path={PROTECTED_PATHS.CONTACT_US} element={<ContactUsPage />} />
            <Route path={PROTECTED_PATHS.SUPPORT} element={<SupportPage />} />
            <Route path={PROTECTED_PATHS.VIEW_FULL_CAR_DETAILS} element={<FullVehicleDetails />} />
            <Route path={PROTECTED_PATHS.PROFILE_SETTINGS} element={<ProfileSettings />} />
            <Route path={PROTECTED_PATHS.PROFILE_SETTINGS_ACCOUNT} element={<AccountInformation />} />
            <Route path={PROTECTED_PATHS.NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
            <Route path={PROTECTED_PATHS.LOGIN_DETAILS_SETTINGS} element={<LoginDetailsSettings />} />
            <Route path={PROTECTED_PATHS.ABOUT_US} element={<AboutUs />} />

            {/* <Route path='*' element={<Navigate to={PROTECTED_PATHS.HOME} />} /> */}
          </Route>
        </Route>

        <Route element={<PublicApp user={user} />}>
          <Route element={<PublicLayout />}>
            <Route path={PUBLIC_PATHS.HOME} element={<HomePage />} />
            <Route path={PUBLIC_PATHS.INVENTORY} element={<InventoryPage />} />
            <Route path={PUBLIC_PATHS.FULL_CAR_DETAILS} element={<FullVehicleDetails />} />
            <Route path={PUBLIC_PATHS.CONTACT_US} element={<ContactUsPage />} />
            <Route path={PUBLIC_PATHS.SUPPORT} element={<SupportPage />} />
            <Route path={PUBLIC_PATHS.ABOUT_US} element={<AboutUs />} />
          </Route>
          <Route path={PUBLIC_PATHS.LOGIN} element={<Login />} />
          <Route path={PUBLIC_PATHS.SIGN_UP} element={<Register />} />
          <Route path={PUBLIC_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={PUBLIC_PATHS.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={PUBLIC_PATHS.SET_NEW_PASSWORD} element={<SetNewPassword />} />
        </Route>

        <Route></Route>
        <Route path='*' element={<Navigate to={PUBLIC_PATHS.HOME} />} />
      </Route>
    </Routes>
  );
}

export default App;
