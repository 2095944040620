import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ENDPOINTS } from "../../../services/routes.constants";

export const messagesApiSlice = createApi({
  reducerPath: "messagesApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["messagesApiSlice"],

  endpoints: (builder) => ({
    getMessages: builder.query({
      query: ({ page = 1, limit = 10 }) =>
        `${API_ENDPOINTS.GET_ALL_MESSAGES}?limit=${limit}&page=${page}`,
      transformResponse: (response) => ({
        data: response.data.sort(
          (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
        ),
        totalPages: response.meta.total,
      }),
      providesTags: ["messagesApiSlice"],
    }),

    deleteMessage: builder.mutation({
      query: (messageId) => ({
        url: API_ENDPOINTS.DELETE_MESSAGE(messageId),
        method: "DELETE",
      }),
      invalidatesTags: ["messagesApiSlice"],
    }),

    upadteMarkAsRead: builder.mutation({
      query: (id) => ({
        url: API_ENDPOINTS.UPDATE_MARK_AS_READ(id),
        method: "GET",
      }),
      invalidatesTags: ["messagesApiSlice"],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useDeleteMessageMutation,
  useUpadteMarkAsReadMutation,
} = messagesApiSlice;
