import { Textarea, Stack, Input } from '@chakra-ui/react';
import { FormContentWrapper } from './FormContentWrapper';

export const DescribeYourVahicle = ({ vehicleListingDetails, setVehicleListingDetails }) => {
  return (
    <FormContentWrapper title='Listing Details'>
      <Stack spacing='5'>
        <Input
          placeholder='Neatly used lexus ES350 grey'
          name='name'
          // value={vehicleListingDetails?.name}
          onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, name: e.target.value }))}
        />
        <Input
          placeholder='Price of Vehicle'
          name='price'
          type='number'
          // value={vehicleListingDetails?.price}
          onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, price: e.target.value }))}
        />
        <Textarea
          rows='7'
          resize='none'
          placeholder='Extended Description'
          onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, description: e.target.value }))}
        />
      </Stack>
    </FormContentWrapper>
  );
};
