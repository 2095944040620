import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminFooter } from '../common/AdminFooter';
import { AdminHeader } from '../common/AdminHeader';

export const AdminLayout = () => {
  return (
    <Stack bg='white' minH='100vh'>
      <AdminHeader />
      <Box flex='1'>
        <Outlet />
      </Box>
      <AdminFooter />
    </Stack>
  );
};
