import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../common/Footer';
import { HomePageHeader } from '../common/HomePageHeader';

export const PublicLayout = () => {
  return (
    <>
      <HomePageHeader />
      <Outlet />
      <Footer />
    </>
  );
};
