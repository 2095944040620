import { Center, FormControl, FormLabel, HStack, Img, PinInput, PinInputField, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { AuthPagesBody } from '.';
import { PrimaryButton } from '../../components/common/CustomButtons';
import { useGetNewOTPMutation, useVerifyEmailMutation } from './service';
import OtpTimer from 'otp-timer';
import { errorToast, successToast } from '../../components/common/ToastHandler';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../app/routes';

export const VerifyEmail = () => {
  const [OTP, setOTP] = useState('');
  const [getNewOTP] = useGetNewOTPMutation();
  const [verifyEmail, { isLoading: verifyEmailLoading }] = useVerifyEmailMutation();
  const registeredUserToken = localStorage.getItem('registeredUser') && JSON.parse(localStorage.getItem('registeredUser'))?.data?.token;
  const unverifiedUserToken = localStorage.getItem('unverifiedUserInfo') && JSON.parse(localStorage.getItem('unverifiedUserInfo'))?.message?.token;
  const navigate = useNavigate();

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    await verifyEmail({ OTP, registeredUserToken: registeredUserToken || unverifiedUserToken })
      .unwrap()
      .then(() => {
        successToast('SuccessFully Verified. You can Proceed to login');
        navigate(PUBLIC_PATHS.LOGIN);
      })
      .catch((error) => errorToast(error.data.message));
  };

  const resendOTP = async () => {
    await getNewOTP(registeredUserToken || unverifiedUserToken)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast(error.data.message));
  };

  return (
    <AuthPagesBody>
      <Center minH='75vh' flexDirection='column'>
        <FormControl as={'form'} maxW='400px' mx='auto' onSubmit={handleVerifyEmail}>
          <Img src='/images/Lock.png' alt='Lock' />
          <Text pt='1rem' pb='2rem' textStyle='h3' fontSize='2rem'>
            Verify Your Email
          </Text>

          <FormLabel m='0' htmlFor='email'>
            Enter The Code Sent to your Email
          </FormLabel>
          <HStack py='10' gap='5px'>
            <PinInput type='alphanumeric' onChange={(val) => setOTP(val)}>
              <PinInputField autoFocus />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>

          <HStack justify='space-between' pb='10'>
            <OtpTimer
              minutes={5}
              seconds={10}
              textColor='#434eb5'
              background='transparent'
              buttonColor='#731012'
              text='You can request a new code in:'
              ButtonText="Didn't get Code? Resend code"
              resend={resendOTP}
            />
          </HStack>
          <PrimaryButton isDisabled={OTP.length < 6} isLoading={verifyEmailLoading} type='submit' w='full'>
            Verify Email
          </PrimaryButton>
        </FormControl>
      </Center>
    </AuthPagesBody>
  );
};
