import { Box, Card, CardBody, CardFooter, Center, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { SiBmw } from 'react-icons/si';
import { EmptyState } from '../../../../components/common/EmptyState';
import { fallBackImg } from '../../../../components/common/extras';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { useGetWindowSize } from '../../../../hooks/useGetWindowSize';
import { dynamicPathhandler, formatInThousands } from '../../../../utils/utils';
import { useGetCarByCategoryQuery } from '../components/service';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRole } from '../../../../redux/features/userSlice';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../../app/routes';

const { FULL_CAR_DETAILS } = PUBLIC_PATHS;
const { VIEW_FULL_CAR_DETAILS } = PROTECTED_PATHS;

export const FavouriteDeal = () => {
  const { data: favouritCars, isLoading } = useGetCarByCategoryQuery('favorites');
  const windowSize = useGetWindowSize();
  const [numberOfSlidesToRender, setNumberOfSlidesToRender] = useState(1);

  useEffect(() => {
    if (windowSize <= 600) {
      setNumberOfSlidesToRender(1);
    } else if (windowSize < 700) {
      setNumberOfSlidesToRender(2);
    } else if (windowSize < 1200) {
      setNumberOfSlidesToRender(3);
    } else {
      setNumberOfSlidesToRender(4);
    }
  }, [windowSize]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <CarouselProvider
        infinite={true}
        // visibleSlides={3.3}
        visibleSlides={numberOfSlidesToRender + 0.2}
        isPlaying={true}
        totalSlides={favouritCars?.data?.length}
        //   naturalSlideWidth={200}
        //   naturalSlideHeight={480}
        isIntrinsicHeight={true}
      >
        <Stack as='section'>
          <HStack justify='space-between'>
            <Text textStyle='h3' py='5'>
              Favorite Deals
            </Text>
            <HStack gap='10px'>
              {/* <Text mr='3'>
                {numberOfSlidesToRender}/{favouritCars?.data?.length}
              </Text> */}
              <ButtonBack>
                <Center p='2' bg='#d5d5d5' borderRadius='5px' cursor='pointer'>
                  <BsArrowLeft size={16} color='black' />
                </Center>
              </ButtonBack>

              <ButtonNext>
                <Center p='2' bg='#d5d5d5' borderRadius='5px' cursor='pointer'>
                  <BsArrowRight size={16} color='black' />
                </Center>
              </ButtonNext>
            </HStack>
          </HStack>

          {!favouritCars?.data?.length && !isLoading && <EmptyState />}
          {favouritCars?.data?.length && !isLoading && (
            <Box>
              <Slider className='slider'>
                <Flex gap='5'>
                  {favouritCars?.data?.map((favouritCar, index) => (
                    <Slide index={(e) => e} className='fav-slide' key={nanoid()}>
                      <FavouriteDealCard index={index} favouritCar={favouritCar} />
                    </Slide>
                  ))}
                </Flex>
              </Slider>
            </Box>
          )}
        </Stack>
      </CarouselProvider>
    </Stack>
  );
};

const FavouriteDealCard = ({ favouritCar }) => {
  const navigate = useNavigate();
  const user = useSelector(userRole);
  const parentPath = dynamicPathhandler(FULL_CAR_DETAILS);
  const loggedinCustomrParentPath = dynamicPathhandler(VIEW_FULL_CAR_DETAILS);

  return (
    <Card
      color='Gray'
      w='300px'
      shadow='xs'
      bg='rgba(255, 255, 255, 0.5)'
      border='1px solid #f5f5f5'
      onClick={() => navigate(!user ? `${parentPath}/${favouritCar.id}` : `${loggedinCustomrParentPath}/${favouritCar.id}`, { state: favouritCar })}
    >
      <CardBody>
        <Img
          loading='lazy'
          fallback={fallBackImg}
          w='100%'
          h='180px'
          borderTopRadius='3px'
          objectFit='cover'
          src={favouritCar?.images[0].url || '/images/car.png'}
          alt='trending car'
        />
        <Stack mt='6' spacing='3'>
          <Text textStyle='h4'>
            {favouritCar?.year} {favouritCar?.name}
          </Text>
          <HStack>
            <Text fontSize='12px'>{favouritCar?.Mileage === 'undefined' ? 'unknown' : favouritCar?.Mileage} KM/hour</Text>
            <Text fontSize='12px'>#{favouritCar?.ref_id}</Text>
          </HStack>

          <HStack>
            <Center border='1px solid #e5e5e5' p='2' borderRadius='5px'>
              <SiBmw size={18} />
            </Center>
            <Stack spacing={0}>
              <Text textStyle='h2' fontSize='12px'>
                {favouritCar?.warranty === 'no warranty' ? 'No warranty' : `${favouritCar?.warranty}yrs warranty`}
              </Text>
              <Text as='small'>{favouritCar?.availability ? 'in Stock' : 'Out of Stock'}</Text>
            </Stack>
          </HStack>
        </Stack>
      </CardBody>

      <CardFooter pt='0'>
        <HStack w='100%' justify='space-between'>
          <Stack spacing={0}>
            <Text textStyle='h4' fontSize='12px'>
              $ {formatInThousands(favouritCar?.price)}
            </Text>
            <Text as='small'>Price</Text>
          </Stack>
          {/* <PrimaryButton size='sm'>Place Order</PrimaryButton> */}
        </HStack>
      </CardFooter>
    </Card>
  );
};
