import { Button, Flex, Img, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FormContentWrapper } from './FormContentWrapper';
import { MdCloudUpload, MdOutlineDeleteForever } from 'react-icons/md';
import { BiTrashAlt } from 'react-icons/bi';
import { RiEditFill } from 'react-icons/ri';
import ImageUploading from 'react-images-uploading';

export const UploadPhotos = ({ setPhotos, photos, setPickLength }) => {
  const [images, setImages] = useState([]);
  const maxNumber = 15;

  console.log('images', images);

  const onChange = (imageList, onError) => {
    // data for submit
    setPickLength(imageList.length);
    setImages(imageList);
    setPhotos(imageList);
  };

  return (
    <FormContentWrapper title={'Upload Photos'}>
      <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey='data_url' acceptType={['jpg', 'jpeg', 'gif', 'png']}>
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
          // write your building UI
          <>
            {errors?.maxNumber && <Text color='crimson'>Number of selected images cannot exceed maximum of {maxNumber}</Text>}
            {errors?.acceptType && <Text color='crimson'>images can only be 'jpg', 'jpeg', 'gif', 'png' formats</Text>}
            {errors?.maxFileSize && <Text color='crimson'>Selected image size exceed maxFileSize</Text>}
            <Stack cursor='pointer' borderRadius='5px' px='5' py='2' border='2px solid #ededed'>
              <Flex justify='space-around' py='5' gap='2rem' direction={['column', 'row']}>
                <Button
                  leftIcon={<MdCloudUpload color='green' size={20} />}
                  type='button'
                  style={isDragging ? { color: 'red' } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Select or Drag Photos
                </Button>

                <Button leftIcon={<BiTrashAlt size={20} color='crimson' />} onClick={onImageRemoveAll}>
                  Remove all Photos
                </Button>
              </Flex>

              <SimpleGrid columns={[2, 2, 3]} rowGap='10' pt='5' columnGap={['5']} justifyItems='center'>
                {imageList?.map((image, index) => (
                  <Flex key={index} gap='0'>
                    <Img src={image.data_url} alt='' width='100px' h='90px' />
                    <Stack>
                      <Button color='white' bg='teal.300' size='xs' w='fit-content' onClick={() => onImageUpdate(index)}>
                        <RiEditFill />
                      </Button>
                      <Button color='white' bg='red.300' size='xs' w='fit-content' onClick={() => onImageRemove(index)}>
                        <MdOutlineDeleteForever />
                      </Button>
                    </Stack>
                  </Flex>
                ))}
              </SimpleGrid>
            </Stack>
          </>
        )}
      </ImageUploading>
    </FormContentWrapper>
  );
};
