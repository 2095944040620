import { HStack, Stack, Text } from '@chakra-ui/react';
import { FiUserPlus } from 'react-icons/fi';
import CustomModal from '../../components/common/CustomModal';
import { PageWrapper } from '../../components/common/PageWrapper';
import { AddAdminForm } from './components/AddAdminForm';
import { AllAdminsTable } from './components/AllAdminsTable';

export const ManageAdmins = () => {
  return (
    <Stack bg='white' spacing='5'>
      {/* <AddAdminHeader /> */}
      <PageWrapper>
        <HStack justify='space-between' border='2px solid #ededed' p='3'>
          <Text fontSize='18px'>All Admins</Text>
          <CustomModal btnTitle='Add Admin' buttonProps={{ leftIcon: <FiUserPlus />, color: 'brand.400' }}>
            <AddAdminForm />
          </CustomModal>
        </HStack>
        <AllAdminsTable />
      </PageWrapper>
    </Stack>
  );
};
