import { Button, Flex, Img, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdCloudUpload, MdOutlineDeleteForever } from 'react-icons/md';
import { FormContentWrapper } from '../../add-new-vehicle/components/FormContentWrapper';
import { BiTrashAlt } from 'react-icons/bi';
import ImageUploading from 'react-images-uploading';

export const EditUploadedPhotos = ({ setImageToRemove, setPhotos, carDetails }) => {
  const [images, setImages] = useState([]);
  const maxNumber = 15;

  const onChange = (imageList, addUpdateIndex, onError) => {
    // data for submit
    setImages(imageList);
    setPhotos(imageList);
  };

  useEffect(() => {
    setImages(carDetails?.images);
  }, []);

  const removeImage = (onImageRemove, index, image) => {
    onImageRemove(index);

    setImageToRemove((prev) => [...prev, { removed_image_id: image.id }]);
  };

  return (
    <FormContentWrapper title={'Upload Photos'}>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey='url'
        acceptType={['jpg', 'jpeg', 'gif', 'png']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <>
            {errors?.maxNumber && (
              <Text color='crimson'>
                Number of selected images cannot exceed maximum of {maxNumber}
              </Text>
            )}
            {errors?.acceptType && (
              <Text color='crimson'>images can only be 'jpg', 'jpeg', 'gif', 'png' formats</Text>
            )}
            {errors?.maxFileSize && (
              <Text color='crimson'>Selected image size exceed maxFileSize</Text>
            )}
            <Stack cursor='pointer' borderRadius='5px' px='5' py='2' border='2px solid #ededed'>
              <Flex justify='space-around' py='5' gap='2rem' direction={['column', 'row']}>
                <Button
                  leftIcon={<MdCloudUpload color='green' size={20} />}
                  type='button'
                  style={isDragging ? { color: 'red' } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Select Photos here
                </Button>

                <Button
                  leftIcon={<BiTrashAlt size={20} color='crimson' />}
                  onClick={onImageRemoveAll}
                >
                  Remove all images
                </Button>
              </Flex>

              <SimpleGrid
                columns={[2, 2, 3]}
                rowGap='10'
                pt='5'
                columnGap='5'
                justifyItems='center'
              >
                {imageList?.map((image, index) => (
                  <Flex key={index} gap='2'>
                    <Img src={image?.url} alt='' width='100px' h='90px' />
                    <Stack>
                      {/* <Button color='white' bg='teal.300' size='xs' w='fit-content' onClick={() => onImageUpdate(index)}>
                        <RiEditFill />
                      </Button> */}
                      <Button
                        color='white'
                        bg='red.300'
                        size='xs'
                        w='fit-content'
                        // onClick={() => onImageRemove(index)}
                        onClick={() => removeImage(onImageRemove, index, image)}
                      >
                        <MdOutlineDeleteForever />
                      </Button>
                    </Stack>
                  </Flex>
                ))}
              </SimpleGrid>
            </Stack>
          </>
        )}
      </ImageUploading>
    </FormContentWrapper>
  );
};
