import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINTS } from '../../../services/routes.constants';

export const subscribersApiSlice = createApi({
  reducerPath: 'subscribersApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['subscribersApiSlice'],

  endpoints: (builder) => ({
    getAllSubcribers: builder.query({
      query: () => ({
        url: API_ENDPOINTS.GET_ALL_SUBSCRIBERS,
      }),
      transformResponse: (responseData) => responseData?.data?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      providesTags: ['subscribersApiSlice'],
    }),
  }),
});

export const { useGetAllSubcribersQuery } = subscribersApiSlice;
