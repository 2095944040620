import { Stack, StackDivider, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { EmptyState } from '../../../../components/common/EmptyState';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { useGetCarByCategoryQuery } from '../../home/components/service';
import { SingleVehicle } from '../../inventory/components/SingleVehicle';

export const PopularVehicles = () => {
  const { data: popularVehicles, isLoading } = useGetCarByCategoryQuery('popular');

  return (
    <Stack>
      <Text textStyle='h4' fontSize={['1.3rem', '1.3rem', '1.5rem', '2rem']}>
        Popular vehicles available
      </Text>

      <Stack spacing='5' divider={<StackDivider borderColor='gray.300' />}>
        {isLoading && <FullPageLoader />}

        {!isLoading && popularVehicles?.data?.length && popularVehicles?.data?.slice(0, 3)?.map((car) => <SingleVehicle key={nanoid()} placeOrder />)}

        {!isLoading && !popularVehicles?.data.length && <EmptyState msg='No Listing for popular' />}
      </Stack>
    </Stack>
  );
};
