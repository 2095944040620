import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    // primary: '#731012',
    Gray: '#9A9EA7',
    lightGray: '#F5F6F7',
    blueShade: '#252B42',
    brand: {
      100: '#F9F3F3',
      200: 'rgba(244, 236, 236, 1)',
      300: '#C49B9B',
      400: 'rgba(115, 16, 18, 0.72)',
      500: '#731012',
    },
  },
  textStyles: {
    h1: {
      fontSize: ['24px', '32px', '38px', '45px'],
      fontWeight: 700,
      letterSpacing: '-2%',
    },
    h2: {
      fontSize: ['36px', '36px', '48px'],
      fontWeight: 'semibold',
      letterSpacing: '-1%',
      color: '#000929',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 700,
      color: '#0D0A19',
    },
    h4: {
      fontSize: '14px',
      fontWeight: 700,
      color: '#0D0A19',
    },
  },

  colorSheme: {
    brand: '#731012',
  },
  styles: {
    global: {
      body: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#4D5461',
        // color:'#000929',
        fontFamily: "'Inter', sans-serif",
      },
    },
  },
});

export const border = '2px solid #ededed';
export const borderRadius = '4px';
export const labelStyle = { m: 0 };
