import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { PrimaryButton } from '../../../components/common/CustomButtons';
import { useAddAdminMutation } from '../../../redux/features/api/superAdminApilSlice';
import { disableForObject } from '../../../utils/utils';
import { errorToast } from '../../../components/common/ToastHandler';

export const AddAdminForm = ({ onClose }) => {
  const [newAdminInfo, setNewAdminInfo] = useState({ name: '', email: '' });
  const [addAdmin, { isLoading }] = useAddAdminMutation();
  const { isOpen, onOpen } = useDisclosure();
  const [message, setMessage] = useState('');

  const onChange = (e) => {
    setNewAdminInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddAdmin = () => {
    addAdmin(newAdminInfo)
      .unwrap()
      .then((data) => {
        setMessage(data.message);
        data.message && onOpen();
      })
      .catch((error) => {
        if (error.originalStatus === 200) {
          errorToast('Email already in Use');
        }
      });
  };

  return (
    <Stack>
      <HStack borderBottom='2px solid #ededed' justify='space-between' p='6'>
        <Text textStyle='h3'>New Member</Text>
        <GrClose size={20} cursor='pointer' onClick={onClose} />
      </HStack>
      <FormControl p='7'>
        <FormLabel m={0}>Full Name</FormLabel>
        <Input name='name' onChange={onChange} placeholder='First name and Last name' />
        <FormLabel m={0} my='10'>
          Email
        </FormLabel>
        <Input name='email' onChange={onChange} type='email' placeholder='cirma@yahoo.com' />
        <HStack justify='flex-end' gap='2rem' py='10'>
          <Button onClick={onClose} w='120px' type='button' variant='outline' color='brand.500'>
            Cancel
          </Button>
          <PrimaryButton isLoading={isLoading} isDisabled={!disableForObject(newAdminInfo)} onClick={handleAddAdmin} w='120px' type='submit'>
            Save
          </PrimaryButton>
        </HStack>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
        <ModalOverlay />
        <ModalContent p='5'>
          <ModalBody as={Flex} flexDirection='column' alignItems={'center'}>
            <BsCheckCircleFill size={35} color='green' />
            <Text py='5' textAlign='center' textStyle='h4' fontSize={'16px'}>
              {message}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button w='full' colorScheme='brand' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
