import { Circle, Img, Stack, Text } from '@chakra-ui/react';
import { AiOutlineHeart } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../../../app/routes';
import { fallBackImg } from '../../../../components/common/extras';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';
import { userRole } from '../../../../redux/features/userSlice';
import { formatInThousands } from '../../../../utils/utils';
import { useMarkAsFavouriteMutation } from './service';

export const TrendingCard = ({ trendingCar, refetch }) => {
  const user = useSelector(userRole);
  const navigate = useNavigate();
  const [markAsFavourite, { isLoading }] = useMarkAsFavouriteMutation();

  const markAsFav = async (trendingCar) => {
    if (!user) return navigate(PUBLIC_PATHS.LOGIN);

    await markAsFavourite({ inventory_id: trendingCar.id })
      .unwrap()
      .then((data) => successToast(data?.message?.status))
      .catch((error) => {
        errorToast();
      })
      .finally(() => refetch());
  };

  return (
    <Stack shadow='sm' bg='white' w='fit-content' borderRadius='3px' pos='relative'>
      <Img fallback={fallBackImg} w='260px' h='220px' borderTopRadius='3px' objectFit='cover' src={trendingCar?.images[0]?.url} alt='trending car' />

      <Circle onClick={() => markAsFav(trendingCar)} className='trending__like' color='brand.500' bg={trendingCar?.favorite ? 'brand.400' : 'lightGray'}>
        {isLoading ? <Text lineHeight={1}>...</Text> : <AiOutlineHeart size={16} />}
      </Circle>

      <Stack p='2'>
        <Text textStyle='h4'>{trendingCar?.name}</Text>
        <Text textStyle='h4'>${formatInThousands(trendingCar?.price)}</Text>
        {/* <Text fontSize='13px' color='Gray'>
          {formatInThousands(trendingCar?.vin)} vehicles
        </Text> */}
      </Stack>
    </Stack>
  );
};
