import { Box, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../common/Footer';
import { LoggedInUserHeader } from '../common/LoggedInUserHeader';

export const LoggedInCustomerLayout = () => {
  return (
    <Stack minH='100vh'>
      <LoggedInUserHeader />
      <Box flex='1'>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  );
};
