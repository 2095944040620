import { Box, Flex, Img, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

export const WeMakeItEasy = () => {
  return (
    <Flex direction={['column', 'column', 'row']} pt='8rem' pb='3rem' gap='3rem' justify='space-between' align='center'>
      <Box flex='1'>
        <Img loading='lazy' src='/images/Image.svg' alt='we make it easrier' />
      </Box>
      <Tabs variant='unstyled' flex='1' maxW='450px' mb='5'>
        <TabList gap='1rem'>
          <Tab fontWeight='700' _selected={selectedtab}>
            For Buyers
          </Tab>
          <Tab fontWeight='700' _selected={selectedtab}>
            For Partners
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel h='210px' px={0}>
            <Text textStyle='h3' fontSize='1.5rem' py='5'>
              We Make It Easy For Our Buyers
            </Text>
            <Text>We facilitate buyers in locating the highest quality cars at wallet-friendly prices, while also informing them of the newest models and upgrades</Text>
          </TabPanel>
          <TabPanel h='210px' px={0}>
            <Text textStyle='h3' fontSize='1.5rem' py='5'>
              We Make It Easy For Our Partners
            </Text>
            <Text>Our partners don't have to worry about overpriced rates again as we offer quality cars at affordable prices.</Text>
            <br />
            <Text>We are certified car dealers licensed by the United States of America and are transparent in all of our dealings.</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

const selectedtab = { fontWeight: '700', color: 'brand.400', bg: 'white', shadow: 'sm', borderRadius: '4px' };
