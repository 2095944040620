import {
  Avatar,
  Button,
  Flex,
  Img,
  List,
  Box,
  Stack,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useNavigate, NavLink, Link, useLocation } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../app/routes';
import { useGetUserProfileQuery } from '../../redux/features/api/profileApiSlice';
import { logout } from '../../redux/features/userSlice';
import { PrimaryButton, OutlinedButton } from './CustomButtons';
import { adminnNavLinks, adminMobileNavLinks } from './extras';
import { FullPageLoader } from './FullPageLoader';
import { activeStyle } from './HomePageHeader';
import { PageWrapper } from './PageWrapper';
import { AiOutlineMenuUnfold } from 'react-icons/ai';

export const AdminHeader = () => {
  const { data: profile, isLoading } = useGetUserProfileQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [pathname]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('persist:#gbe#xp&st');

    window.location.reload(false);
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper>
      <Flex as='nav' justify='space-between' align='center' wrap='wrap' gap='1rem' pb='5'>
        <Link style={{ order: '-5' }} to={PROTECTED_PATHS.ADMIN_LISTING}>
          <Img pos='relative' left='-20px' src='/images/logo.svg' alt='gabe' />
        </Link>

        <Flex as={List} gap='2rem' flex='0.9' justify={['center']} display={['none', 'none', 'none', 'flex']}>
          {adminnNavLinks?.map((link) => (
            <ListItem fontWeight='semibold' key={nanoid()}>
              <NavLink to={link?.to ? link.to : '/'} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                {link?.title}
              </NavLink>
            </ListItem>
          ))}
        </Flex>

        {/* <CustomDrawer
          btn={
            <Square borderRadius='5px' bg='whiteAlpha.700' p='2'>
              <VscBellDot size={22} />
            </Square>
          }
        >
          <AdminNotifications />
        </CustomDrawer> */}

        <PrimaryButton
          size={['sm', 'md']}
          leftIcon={<BiPlusCircle size={20} />}
          onClick={() => navigate(PROTECTED_PATHS.ADMIN_NEW_LISTING)}
          display={['none', 'none', 'none', 'block']}
        >
          Add New Listings
        </PrimaryButton>

        <Menu>
          <MenuButton
            display={['none', 'none', 'none', 'flex']}
            w='190px'
            size={['sm', 'md']}
            bg='gray.200'
            as={Button}
            leftIcon={<Avatar size={['xs', 'sm']} src='' name='Marian Abekun' />}
          >
            {profile?.data?.name}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate(PROTECTED_PATHS.ADMIN_PROFILE)} fontWeight='500' fontSize='16px'>
              Account Settings
            </MenuItem>
            <MenuItem fontWeight='500' fontSize='16px' onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>

        <Button bg='transparent' onClick={onOpen} display={['block', 'block', 'block', 'none']}>
          <AiOutlineMenuUnfold size={30} />
        </Button>
        <Drawer size='sm' isOpen={isOpen} placement='right' onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box p='10' pt='20'>
              <Stack as={List} gap='2rem'>
                {adminMobileNavLinks?.map((link) => (
                  <ListItem borderBottom='1px solid #eee' fontSize='1rem' fontWeight='500' key={nanoid()}>
                    <NavLink to={link?.to ? link.to : '/'} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                      {link?.title}
                    </NavLink>
                  </ListItem>
                ))}
              </Stack>
              <Flex pt='24' gap='2rem' justify='space-between'>
                <Menu>
                  <MenuButton w='100%' bg='gray.200' as={Button} leftIcon={<Avatar size='sm' src={profile?.data?.profile_image} name={profile?.data?.name} />}>
                    {profile?.data?.name}
                  </MenuButton>
                </Menu>

                <OutlinedButton w='100%' onClick={handleLogout}>
                  Logout
                </OutlinedButton>
              </Flex>
            </Box>
          </DrawerContent>
        </Drawer>
      </Flex>

      {/* mobile nav */}
    </PageWrapper>
  );
};
