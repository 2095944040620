export const PUBLIC_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  SET_NEW_PASSWORD: '/reset',

  //pages
  INVENTORY: '/inventory',
  FULL_CAR_DETAILS: '/inventory/car-details/:carID',
  CONTACT_US: '/contact-us',
  SUPPORT: '/support',
  ABOUT_US: '/about-us',
};

export const PROTECTED_PATHS = {
  //customer
  HOME: '/customer/home',
  INVENTORY: '/customer/inventory',
  FAVOURITES: '/customer/favourites',
  MESSAGES: '/customer/messages',
  CONTACT_US: '/customer/contact-us',
  SUPPORT: '/customer/support',
  VIEW_FULL_CAR_DETAILS: '/customer/inventory/car-details/:carID',
  PROFILE_SETTINGS: '/customer-profile-settings',
  PROFILE_SETTINGS_ACCOUNT: '/customer-profile-settings/account',
  NOTIFICATION_SETTINGS: '/customer-profile-settings/notifications',
  LOGIN_DETAILS_SETTINGS: '/customer-profile-settings/login-details',
  ABOUT_US: '/customer/about-us',

  //admin
  ADMIN_LISTING: '/admin/listing',
  ADMIN_CUSTOMERS: '/admin/customers',
  ADMIN_MESSEGES: '/admin/messeges',
  ADMIN_PROFILE: '/admin-profile-settings',
  ADMIN_PROFILE_SETTINGS_ACCOUNT: '/admin-profile-settings/account',
  ADMIN_NOTIFICATION_SETTINGS: '/admin-profile-settings/notifications',
  ADMIN_LOGIN_DETAILS_SETTINGS: '/admin-profile-settings/login-details',
  ADMIN_ACCOUNT_INFORMATION: '/admin-profile-settings/admin-account-information',
  ADMIN_NEW_LISTING: '/admin/new-listings',
  ADMIN_EDIT_LISTING: '/admin/edit-listings/:carID',
  LISTING_CREATED: '/listing-created',
  ADMIN_SUBSCRIBERS: '/admin-subsribers',

  //supperadmin
  SUPER_ADMIN_MANAGE_ADMINS: '/super-admin/add-admins',
  SUPER_ADMIN_LISTING: '/super-admin/listing',
  SUPER_ADMIN_CUSTOMERS: '/super-admin/customers',
  SUPER_ADMIN_MESSEGES: '/super-admin/messeges',
  SUPER_ADMIN_PROFILE: '/super-admin-profile-settings',
  SUPER_ADMIN_PROFILE_SETTINGS_ACCOUNT: '/super-admin-profile-settings/account',
  SUPER_ADMIN_NOTIFICATION_SETTINGS: '/super-admin-profile-settings/notifications',
  SUPER_ADMIN_LOGIN_DETAILS_SETTINGS: '/super-admin-profile-settings/login-details',
  SUPER_ADMIN_ACCOUNT_INFORMATION: '/superadmin-profile-settings/admin-account-information',
  SUPER_ADMIN_NEW_LISTING: '/super-admin/new-listings',
  SUPER_ADMIN_EDIT_LISTING: '/super-admin/edit-listings/:carID',
  SUPER_LISTING_CREATED: '/super-listing-created',
  SUPER_ADMIN_SUBSCRIBERS: '/super-admin-subsribers',
};
