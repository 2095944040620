import axios from 'axios';

export * from './routes.constants';

// const baseURL = 'https://emanager-webportal.onrender.com/';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json ',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
});

const addTokenToRequest = async (req) => {
  const token = JSON.parse(JSON.parse(localStorage.getItem('persist:#gbe#xp&st'))?.user)?.user?.data?.token || null;
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;

export const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json ',
  'Access-Control-Allow-Headers': 'Content-Type',
};

export const multipartHeaders = {
  Accept: 'application/json',
  'Content-type': 'multipart/form-data',
  'Access-Control-Allow-headers': 'Content-Type',
};
