import { Box, Button, Flex, FormControl, HStack, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { AiFillCar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/common/CustomButtons';
import { infoToast } from '../../../components/common/ToastHandler';
import { DescribeYourVahicle } from './components/DescribeYourVahicle';
import { listingTabs } from './components/extras';
import { ListingInformation } from './components/ListingInformation';
import { MajorFeatures } from './components/MajorFeatures';
import { UploadPhotos } from './components/UploadPhotos';
import { useMultiStepForm } from './components/useMultiStepForm';
import { addNewVehicleListing } from './service/addNewVehicle';

export const AddNewVehicle = () => {
  const user = useSelector((state) => state?.persistedReducer?.user?.user?.data?.role);
  const [isMobile] = useMediaQuery('(max-width: 920px)');
  const navigate = useNavigate();
  const [vehicleListingDetails, setVehicleListingDetails] = useState({});
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pickLength, setPickLength] = useState(null);

  const { step, isFirstStep, isLastStep, back, next, currentIndex } = useMultiStepForm([
    <MajorFeatures vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />,
    <ListingInformation vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />,
    <UploadPhotos photos={photos} setPhotos={setPhotos} setPickLength={setPickLength} />,
    <DescribeYourVahicle vehicleListingDetails={vehicleListingDetails} setVehicleListingDetails={setVehicleListingDetails} />,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (currentIndex === 2 && pickLength <= 2) return infoToast('Pick at least 3 images');
    if (!isLastStep) return next();
    setLoading(true);

    const formData = new FormData();

    photos.forEach((photo) => formData.append('images[]', photo.file));
    formData.append('interior_color', vehicleListingDetails?.interior_color);
    formData.append('exterior_color', vehicleListingDetails?.exterior_color);
    formData.append('year', vehicleListingDetails?.year);
    formData.append('vin', vehicleListingDetails?.vin);
    formData.append('mileage', vehicleListingDetails?.miles);
    formData.append('body_style', vehicleListingDetails?.body_style);
    formData.append('door', vehicleListingDetails?.door);
    formData.append('category', vehicleListingDetails?.category);
    formData.append('drive_train', vehicleListingDetails?.driveTrain);
    formData.append('fuel_type', vehicleListingDetails?.fuel_type);
    formData.append('transmission', vehicleListingDetails?.transmission);
    formData.append('drive', 'no idea');
    formData.append('seat', vehicleListingDetails?.seat);
    formData.append('availability', '1');
    formData.append('model_id', vehicleListingDetails.model_id);
    formData.append('name', vehicleListingDetails.name);
    formData.append('price', vehicleListingDetails.price);
    formData.append('description', vehicleListingDetails.description);
    formData.append('warranty', vehicleListingDetails.warranty);
    formData.append('color', 'red');

    addNewVehicleListing(formData, navigate, user, setLoading);
  };

  return (
    <Flex py='10' columnGap={'4%'} direction={['column', 'column', 'column', 'row']}>
      <Stack spacing='5' pt={[0, '4rem']} pb={['2rem']} w={['100%', '100%', '100%', '250px', '220px']} direction={['row', 'row', 'row', 'column']}>
        {listingTabs?.map((tab, i) => (
          <HStack key={nanoid()} fontSize={['0.8rem', '1rem']} p='2' borderRadius='5px' className={currentIndex === i && 'listing-active-tab'}>
            {!isMobile && <AiFillCar />}
            <Text>{tab?.title}</Text>
          </HStack>
        ))}
      </Stack>
      <Box w='100%' maxW='700px'>
        <FormControl as='form' isRequired onSubmit={onSubmit}>
          <Box p='5%' borderRadius='5px' border='2px solid #eee'>
            {step}
          </Box>

          <Flex justify='flex-end' py='10' gap='1.5rem'>
            {!isFirstStep && (
              <>
                <Button w='150px' type='button' bg='none' color='brand.500' border='2px solid' borderColor='brand.100' onClick={back}>
                  Previous
                </Button>
              </>
            )}

            <PrimaryButton w='150px' type='submit' isLoading={loading}>
              {isLastStep ? 'Finish' : 'Next'}
            </PrimaryButton>
          </Flex>
        </FormControl>
      </Box>
    </Flex>
  );
};
