import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dynamicSearchEndpoint } from '..';
import { API_ENDPOINTS } from '../../../../services/routes.constants';

export const inventoryApiSlice = createApi({
  reducerPath: 'inventoryApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set('authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  tagTypes: ['inventoryApiSlice', 'modelApiSlice'],

  endpoints: (builder) => ({
    getAllCarsInventory: builder.query({
      query: () => API_ENDPOINTS.ALL_CARS_INVENTORIES,
      providesTags: ['inventoryApiSlice'],
    }),

    getCarFullDetails: builder.query({
      query: (carId) => API_ENDPOINTS.GET_CAR_FULL_DETAILS(carId),
      providesTags: ['inventoryApiSlice'],
    }),

    filterSearch: builder.query({
      query: (params) => dynamicSearchEndpoint(params),
      providesTags: ['inventoryApiSlice'],
    }),
  }),
});

export const { useGetAllCarsInventoryQuery, useGetCarFullDetailsQuery, useFilterSearchQuery } = inventoryApiSlice;
