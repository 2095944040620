import { Button, Flex, HStack, Img, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { MdAddShoppingCart } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../app/routes';
import { userRole } from '../../redux/features/userSlice';

export const Footer = () => {
  const user = useSelector(userRole);
  const navigate = useNavigate();
  return (
    <>
      <SimpleGrid columns={[1, 2, 3, 4, 5]} p='4%' gap='3rem' rowGap={['10']}>
        <VStack bg='gray.100' borderRadius='5px' shadow='sm' alignSelf={['center', 'center', 'flex-end']} py='7' px='10'>
          <Text fontSize='12px' fontWeight={600}>
            Find your next <br /> awesome vehicle
          </Text>

          <Button
            onClick={() => navigate(user === 'user' ? PROTECTED_PATHS.CONTACT_US : PUBLIC_PATHS.CONTACT_US)}
            bg='white'
            textStyle='h4'
            leftIcon={<MdAddShoppingCart color='crimson' />}
          >
            Contact Support
          </Button>
        </VStack>
        <Stack maxW='180px'>
          <Img w='100px' pos='relative' left='-20px' src='/images/logo.svg' alt='logo' />
          <Text>At Gabe Investments, we specialise in car acquisitions and auctions. </Text>
        </Stack>
        <Stack>
          <Text textStyle='h4'>Gabe Investments</Text>
          <Text color='gray.400' as={Link} to={user ? PROTECTED_PATHS.HOME : PUBLIC_PATHS.HOME}>
            Home
          </Text>
          <Text color='gray.400' as={Link} to={user ? PROTECTED_PATHS.INVENTORY : PUBLIC_PATHS.INVENTORY}>
            Inventory
          </Text>
          <Text color='gray.400' as={Link} to={user ? PROTECTED_PATHS.CONTACT_US : PUBLIC_PATHS.CONTACT_US}>
            Contact
          </Text>
        </Stack>
        <Stack>
          <Text _hover={{ textDecoration: 'underline' }} as={Link} target='_blank' to='#' textStyle='h4'>
            Terms & Privacy
          </Text>
          <Text _hover={{ textDecoration: 'underline' }} as={Link} to='#' color='gray.400'>
            Terms of Use
          </Text>
          <Text
            _hover={{ textDecoration: 'underline' }}
            as={Link}
            target='_blank'
            to='https://us.docworkspace.com/d/cIIO_tPomu-akqgY?utm_source=wps_office_mac&utm_medium=comp_border_bar&utm_content=link'
            color='gray.400'
          >
            Privacy Policy
          </Text>
        </Stack>
        <Stack maxW='180px'>
          <Text textStyle='h4'>Contact Details</Text>
          <Text color='gray.400'>2306 Oak Lane Suite 13, Grand Prairie TX 75051</Text>
          <Text color='gray.400'>+1 (469) 249-2281</Text>
          <HStack gap='5px' pt='4'>
            <Link to='https://www.facebook.com/gabeinvestments?mibextid=LQQJ4d' rel='noreferrer' target={'_blank'}>
              <FaFacebookF size={20} />
            </Link>
            <Link to='https://instagram.com/gabeinvestments?igshid=YmMyMTA2M2Y=' rel='noreferrer' target={'_blank'}>
              <FaInstagram size={20} />
            </Link>
          </HStack>
        </Stack>
      </SimpleGrid>
      <PrivacyPolicy />
    </>
  );
};

export const PrivacyPolicy = () => {
  return (
    <Flex gap='4' align='center' wrap={'wrap'} py='5' justify={'center'}>
      <Text
        textStyle='h4'
        fontSize='16px'
        as={Link}
        to='https://us.docs.wps.com/module/common/loadPlatform/?utm_source=wps_office_mac&utm_medium=comp_border_bar&utm_content=link&sid=cIIO_tPomu-akqgY&v=v2'
      >
        Privacy Policy
      </Text>
      {/* https://www.iubenda.com/privacy-policy/98944637.pdf */}
      <Text as={Link} to='#' textStyle='h4' fontSize='16px'>
        Terms of Use
      </Text>
      <Text>© Gabe Investments {new Date().getFullYear()} All rights reserved</Text>
    </Flex>
  );
};
