import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { AdminFooter } from '../common/AdminFooter';
import { SuperAdminHeader } from '../common/SuperAdminHeader';

export const SuperAdminLayout = () => {
  return (
    <Box bg='white' minH='100vh'>
      <SuperAdminHeader />
      {/* <AdminHeader /> */}
      <Box flex='1' minH='70vh'>
        <Outlet />
      </Box>
      <AdminFooter />
    </Box>
  );
};
