import { PROTECTED_PATHS } from '../../../../app/routes';

export const gotoAccountInformation = (user) => {
  switch (user) {
    case 'admin':
      return PROTECTED_PATHS.ADMIN_PROFILE_SETTINGS_ACCOUNT;

    case 'user':
      return PROTECTED_PATHS.PROFILE_SETTINGS_ACCOUNT;

    case 'super-admin':
      return PROTECTED_PATHS.SUPER_ADMIN_PROFILE_SETTINGS_ACCOUNT;

    default:
      return PROTECTED_PATHS.PROFILE_SETTINGS;
  }
};

export const gotoLoginDetails = (user) => {
  switch (user) {
    case 'admin':
      return PROTECTED_PATHS.ADMIN_LOGIN_DETAILS_SETTINGS;

    case 'user':
      return PROTECTED_PATHS.LOGIN_DETAILS_SETTINGS;

    case 'super-admin':
      return PROTECTED_PATHS.SUPER_ADMIN_LOGIN_DETAILS_SETTINGS;

    default:
      return PROTECTED_PATHS.PROFILE_SETTINGS;
  }
};

export const gotoNotification = (user) => {
  switch (user) {
    case 'admin':
      return PROTECTED_PATHS.ADMIN_NOTIFICATION_SETTINGS;

    case 'user':
      return PROTECTED_PATHS.NOTIFICATION_SETTINGS;

    case 'super-admin':
      return PROTECTED_PATHS.SUPER_ADMIN_NOTIFICATION_SETTINGS;

    default:
      return PROTECTED_PATHS.PROFILE_SETTINGS;
  }
};

export const gotoBackToProfileSettings = (user) => {
  switch (user) {
    case 'admin':
      return PROTECTED_PATHS.ADMIN_PROFILE;

    case 'user':
      return PROTECTED_PATHS.PROFILE_SETTINGS;

    case 'super-admin':
      return PROTECTED_PATHS.SUPER_ADMIN_PROFILE;

    default:
      return PROTECTED_PATHS.PROFILE_SETTINGS;
  }
};
