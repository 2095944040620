import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { isValidElement, Children, cloneElement } from 'react';

export function CustomDrawer({ children, size, btn, ...buttonProps }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      <Button _focus={{ border: 'none' }} p='0' onClick={onOpen} {...buttonProps}>
        {btn}
      </Button>
      <Drawer size={size || 'md'} p='0' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p='0'>{childrenWithProps}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
