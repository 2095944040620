import { Flex, HStack, Text } from '@chakra-ui/react';
import { ImEarth } from 'react-icons/im';
import { BsThreeDots } from 'react-icons/bs';
import { PageWrapper } from './PageWrapper';
import { Link } from 'react-router-dom';

export const AdminFooter = () => {
  return (
    <PageWrapper bg='white' py='10'>
      <Flex justify='space-between' gap='2rem' py='5' wrap='wrap' justifySelf='flex-end'>
        <HStack>
          <Text>© {new Date().getFullYear()} All rights reserved</Text>
          <Text
            textStyle='h4'
            as={Link}
            to='https://us.docs.wps.com/module/common/loadPlatform/?utm_source=wps_office_mac&utm_medium=comp_border_bar&utm_content=link&sid=cIIO_tPomu-akqgY&v=v2'
          >
            Privacy&nbsp;Policy
          </Text>
          <Text textStyle='h4'>Term&nbsp;of&nbsp;Use</Text>
        </HStack>
        <HStack>
          <HStack>
            <ImEarth />
            <Text>English</Text>
          </HStack>
          <HStack>
            <BsThreeDots />
            <Text>USD</Text>
          </HStack>
        </HStack>
      </Flex>
    </PageWrapper>
  );
};
