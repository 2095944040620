import { Box, Input, InputGroup, InputRightElement, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { validateEmail } from '../../../../utils/utils';
import { errorToast, successToast, warningToast } from '../../../../components/common/ToastHandler';
import http from '../../../../services/api';

export const Licence = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const hanleSubmit = async () => {
    if (!email) return warningToast('Please enter an email');
    if (!validateEmail(email)) return warningToast('Invalid email');
    try {
      setLoading(true);
      const { data } = await http.post('/subscriptions', { email });
      successToast(data.message);
      setLoading(false);
    } catch (error) {
      errorToast();
      setLoading(false);
    }
  };
  return (
    <Box bg='blueShade' p='2rem'>
      <VStack textAlign={'center'} mx='auto' color='white' maxW='500px' py='4rem'>
        <Text textStyle='h3' fontSize={'1.3rem'} color='white'>
          Stay on current trends and Information
        </Text>
        <Text textStyle='h2' lineHeight={1} color='white'>
          Subscribe To Our Newsletter
        </Text>
        <Text>You are just a click away from your next best car.</Text>
        <InputGroup py='5'>
          <Input onChange={(e) => setEmail(e.target.value)} color='#111' bg='white' h='3rem' pr={['7rem', '10rem']} type='email' placeholder='Enter your Email' />
          <InputRightElement w>
            <PrimaryButton px={['1rem', '2rem']} mr={['1', '3']} mt='3rem' isLoading={loading} onClick={hanleSubmit}>
              Submit
            </PrimaryButton>
          </InputRightElement>
        </InputGroup>
        <Text fontWeight='200'>
          Join <b>10,000+ </b>other buyers in choosing Gabe Investments
        </Text>
      </VStack>
    </Box>
  );
};
