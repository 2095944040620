import { Avatar, Box, Button, Flex, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { HiOutlineMail } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import { capitalizeFirstLetter, formatDate } from '../../../utils/utils';
import { CautionAlertDialog } from '../../../components/common/CautionAlertDialog';
import { useDeactivateUserMutation, useDeleteUserMutation, useReactivateUserMutation } from '../../../redux/features/api/superAdminApilSlice';
import { errorToast, successToast } from '../../../components/common/ToastHandler';

export function ViewAdminDetails({ admin }) {
  const [deactivateUser, { isLoading }] = useDeactivateUserMutation();
  const [reactivateUser] = useReactivateUserMutation();
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();

  const deactivateAdmin = async () => {
    await deactivateUser(admin.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast());
  };

  const reactivateAdmin = async () => {
    await reactivateUser(admin.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast());
  };

  const handleDeleteAdmin = async () => {
    await deleteUser(admin.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast());
  };

  return (
    <Stack>
      <Text fontWeight='600' fontSize='1.3rem' p='8' shadow='sm'>
        Admin Details
      </Text>
      <Stack p='8' spacing='5'>
        <HStack gap='3rem'>
          <Avatar src='' name='A  f' size='xl' />
          <CautionAlertDialog
            size='xs'
            button={admin?.deleted_at ? 'Reactivate' : 'Deactivate'}
            buttonProps={{ as: Button, isLoading, color: 'white', bg: 'black' }}
            onContinue={admin?.deleted_at ? reactivateAdmin : deactivateAdmin}
          >
            <Text textAlign='center' fontWeight={600} fontSize='1.1rem'>
              Are you sure you want to {admin?.deleted_at ? 'reactivate' : 'deactivate'} this Account?
            </Text>
          </CautionAlertDialog>

          <Flex flex='1' justify='flex-end'></Flex>
        </HStack>
        <Box>
          <Text textStyle='h3'>{admin?.name}</Text>
          <Text>{capitalizeFirstLetter(admin?.role)}</Text>
        </Box>

        <SimpleGrid columns={2} rowGap='7' maxW='400px'>
          <Box>
            <Text {...lightText}>Date Joined</Text>
            <Text fontWeight='600' fontSize='15px'>
              {formatDate(admin?.created_at)}
            </Text>
          </Box>
          <Box>
            <Text {...lightText}>Admin ID</Text>
            <Text fontWeight='600' fontSize='15px'>
              {admin?.ref_id}
            </Text>
          </Box>

          <Box>
            <Text {...lightText}>Status</Text>
            {!admin?.deleted_at ? (
              <Text fontWeight='600' fontSize='15px' color='teal.400'>
                Active
              </Text>
            ) : (
              <Text fontWeight='600' fontSize='15px' color='red.400'>
                Not Active
              </Text>
            )}
          </Box>
          <Box>
            <Text {...lightText}>Role/Position</Text>
            <Text fontWeight='600' fontSize='15px'>
              {capitalizeFirstLetter(admin?.role)}
            </Text>
          </Box>
        </SimpleGrid>

        <Stack py='5'>
          <Text {...lightText}>Contact Information</Text>
          <HStack fontSize='1.2rem'>
            <HiOutlineMail />
            <Text>{admin?.email}</Text>
          </HStack>
          <HStack fontSize='1.2rem'>
            <BsTelephone />
            <Text>{admin?.phone_number || 'Not Available'}</Text>
          </HStack>
        </Stack>
        <Box py='5'>
          <CautionAlertDialog
            size='xs'
            button={'Delete Admin'}
            buttonProps={{ as: Button, isLoading: deleteLoading, color: 'red.500', varaint: 'outline' }}
            onContinue={handleDeleteAdmin}
          >
            <Text textAlign='center' fontWeight={600} fontSize='1.1rem'>
              Are you sure you want to Permanently Delete This Admin?
            </Text>
          </CautionAlertDialog>
        </Box>
      </Stack>
    </Stack>
  );
}

const lightText = { fontSize: '14px', color: '#606E77' };
