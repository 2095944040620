import { Stack, StackDivider } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { EmptyState } from '../../../../components/common/EmptyState';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { Paginator } from '../../../../components/common/Paginator';
import { useGetAllCarsInventoryQuery } from '../service';
import { SingleVehicle } from './SingleVehicle';

export const Vehicle = ({ params, searchResult }) => {
  const { data: allCarsInventory, isLoading, refetch } = useGetAllCarsInventoryQuery();
  const [pageNumber, setPageNumber] = useState(0);
  const [displayedListings, setDisplayedListings] = useState([]);

  const itemsPerPage = 5;
  const pagesVisited = pageNumber * itemsPerPage;

  useEffect(() => {
    Object?.keys(params)?.length
      ? setDisplayedListings(searchResult?.data?.slice(pagesVisited, pagesVisited + itemsPerPage))
      : setDisplayedListings(allCarsInventory?.data?.slice(pagesVisited, pagesVisited + itemsPerPage));
  }, [allCarsInventory?.data, pagesVisited, params, searchResult?.data]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack spacing='5' divider={<StackDivider borderColor='gray.300' />}>
      {displayedListings?.map((car) => (
        <SingleVehicle refetch={refetch} data={car} key={nanoid()} />
      ))}

      {!displayedListings?.length && !isLoading && <EmptyState msg={'Not Found'} />}
      <Paginator data={allCarsInventory?.data || []} setPageNumber={setPageNumber} itemsPerPage={itemsPerPage} />
    </Stack>
  );
};
