import { Box, Img, Stack } from '@chakra-ui/react';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { fallBackImg } from '../../../components/common/extras';
import { FavouriteDeal } from './components/FavouriteDeal';
import { FindCarByModel } from './components/FindCarByModel';
import { HeroBanner } from './components/HeroBanner';
import { Licence } from './components/Licence';
import { Testimonial } from './components/Testimonial';
import { TrendingNearYou } from './components/TrendingNearYou';
import { WeMakeItEasy } from './components/WeMakeItEasy';
import { WhyChooseUs } from './components/WhyChooseUs';

export const HomePage = () => {
  return (
    <Stack overflowX='hidden' w='100vw'>
      <PageWrapper>
        <HeroBanner />
        <TrendingNearYou />
        <WhyChooseUs />
        <FavouriteDeal />
      </PageWrapper>
      <Box py='28' w='100vw' maxW='1800px'>
        <Img loading='lazy' fallback={fallBackImg} w='100%' objectFit='cover' h='100%' maxH='70vh' src='/images/secondbanner.png' alt='hot deal' />
      </Box>
      <Box>
        <PageWrapper>
          <FindCarByModel />
          <WeMakeItEasy />
          <Testimonial />
        </PageWrapper>
      </Box>
      <Licence />
    </Stack>
  );
};
