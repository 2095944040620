import { Box, Circle, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { SlScreenSmartphone } from 'react-icons/sl';
import { useLocation } from 'react-router-dom';
import CustomModal from '../../../../components/common/CustomModal';
import { PageWrapper } from '../../../../components/common/PageWrapper';
import { ChangePhoneNumber } from './ChangePhoneNumber';
import { SettingHead } from './SettingHead';
import { maskNumberFromFront } from '../../../../utils/utils';
import { ChangePassword } from './ChangePassword';

export const LoginDetailsSettings = () => {
  const { state } = useLocation();
  return (
    <PageWrapper>
      <Box maxW='600px' mt='8' mb='12'>
        <SettingHead title={'Login Details'} sub='Your security and login activity' />
        <Stack mt='10' spacing={12}>
          <Text textStyle='h4' fontSize='16px' pt='5'>
            Change password
          </Text>

          <HStack justify='space-between'>
            <Flex gap='1rem'>
              <Circle bg='blackAlpha.100' h='50px' w='50px'>
                <SlScreenSmartphone size={22} />
              </Circle>
              <Box>
                <Text fontSize='15px' textStyle='h4'>
                  {maskNumberFromFront(state?.data?.phone_number, 2) || '+xxx xxxxxxxxxx60'}
                </Text>
                <Text>Keep your primary phone number up-to-date</Text>
              </Box>
            </Flex>
            <CustomModal btnTitle='Manage' buttonProps={buttonProps}>
              <ChangePhoneNumber />
            </CustomModal>
          </HStack>

          <HStack justify='space-between'>
            <Flex gap='1rem'>
              <Circle bg='blackAlpha.100' h='50px' w='50px'>
                <SlScreenSmartphone size={22} />
              </Circle>
              <Box>
                <Text fontSize='15px' textStyle='h4'>
                  ***********
                </Text>
                <Text>You can reset your password</Text>
              </Box>
            </Flex>
            <CustomModal btnTitle='Manage' buttonProps={buttonProps}>
              <ChangePassword />
            </CustomModal>
          </HStack>
        </Stack>
      </Box>
    </PageWrapper>
  );
};

const buttonProps = { _focus: { border: '2px solid #333' }, varaint: 'outline', color: 'black', border: '2px solid #333' };
