import { Box, Button, Flex, HStack, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';
import { GrClose } from 'react-icons/gr';
import { MdEmail } from 'react-icons/md';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';
import { useBlockUserMutation, useDeleteUserMutation } from '../../../../redux/features/api/superAdminApilSlice';
import { formatDate } from '../../../../utils/utils';

export const CustomerDetailsView = ({ onClose, customer }) => {
  const [blockUser, { isLoading }] = useBlockUserMutation();
  const [deleteCustomer, { isLoading: deleteLoading }] = useDeleteUserMutation();

  const handleBlockerUser = async () => {
    await blockUser(customer.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => {
        errorToast();
      });
  };

  const handleDeleteCustomer = async () => {
    await deleteCustomer(customer.id)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch((error) => errorToast())
      .finally(onClose);
  };

  return (
    <Stack py='2' px='10'>
      <HStack justify='space-between' p='4' shadow='sm'>
        <Text textAlign='center' flex='1' textStyle='h3' fontSize='20px'>
          Customer Details Review
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>

      <Stack align='center' py='5'>
        <HStack pb='5' gap='1rem'>
          {/* <PrimaryButton size='sm'>Join Chat</PrimaryButton> */}
          <Button isLoading={isLoading} size='sm' variant='outline' borderColor='brand.500' color='brand.500' onClick={handleBlockerUser}>
            X Block
          </Button>
          <PrimaryButton bg='black' size='sm' isLoading={deleteLoading} onClick={handleDeleteCustomer}>
            Delete
          </PrimaryButton>
        </HStack>
        <VStack w='full' p='5' border='1px solid #ededed'>
          <Text textStyle='h3'> {customer?.name}</Text>
          <Text fontSize='14px'>{customer?.email}</Text>
          <Stack divider={<StackDivider borderColor='gray.300' />} w='full' p='5'>
            <HStack {...flexStyle} bg='brand.100'>
              <Text>ID 657958</Text>
              <Text color='brand.500' fontWeight='600'>
                Customer ID
              </Text>
            </HStack>

            <HStack {...flexStyle}>
              <Text color='gray.500'>Full Name</Text>
              <Text color='gray.900' fontWeight='600'>
                {customer?.name}
              </Text>
            </HStack>
            <HStack {...flexStyle}>
              <Text color='gray.500'>Phone Number</Text>
              <Text color='gray.900' fontWeight='600'>
                {customer?.phone_number || '08165727891'}
              </Text>
            </HStack>

            <HStack {...flexStyle}>
              <Text color='gray.500'>Email</Text>
              <Text color='gray.900' fontWeight='600'>
                {customer?.email}
              </Text>
            </HStack>
            <HStack {...flexStyle}>
              <Text color='gray.500'>Date Registered</Text>
              <Text color='gray.900' fontWeight='600'>
                {formatDate(customer?.created_at)}
              </Text>
            </HStack>
            <Flex pb='5' pt='1' mt='5' w='full' bg='gray.100'>
              <PrimaryButton
                onClick={() => window.open(`mailto:${customer?.email}?subject=Gabe Investments&body=Dear Customer,`)}
                _hover={{ bg: '#262626' }}
                leftIcon={<MdEmail color='#fff' />}
                w='full'
                bg='#000'
              >
                Email Customer
              </PrimaryButton>
            </Flex>
          </Stack>
        </VStack>
      </Stack>
    </Stack>
  );
};

const flexStyle = { justify: 'space-between', py: '2', px: '2' };
