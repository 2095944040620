export const listingTabs = [
  { title: 'Major features' },
  { title: 'Listing Information' },
  { title: 'Upload photos' },
  { title: 'Describe your car' },
];

export const carTypes = [
  // { value: '', label: 'Select your Car type. example Sport Car', disabled: true, selected: true },
  { value: 'suv', label: 'SUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Crossover', label: 'Crossover' },
  { value: 'Convertible', label: 'Convertible' },
  { value: 'Sedan', label: 'Sedan' },
  { value: 'Sports Car', label: 'Sports Car' },
  { value: 'Coupe', label: 'Coupe' },
  { value: 'Minivan', label: 'Minivan' },
  { value: 'Station Wagon', label: 'Station Wagon' },
  { value: 'Pickup Truck', label: 'Pickup Truck' },
  { value: 'Roadster', label: 'Roadster' },
  { value: 'Muscle', label: 'Muscle' },
  { value: 'Microcar', label: 'Microcar' },
  { value: 'Limousine', label: 'Limousine' },
  { value: 'Low Rider', label: 'Low Rider' },
  { value: 'Grand Tourer', label: 'Grand Tourer' },
];

export const colorsArr = [
  'apricot',
  'ash',
  'black',
  'blue',
  'bluetiful',
  'blue green',
  'blue violet',
  'brown',
  'carnation pink',
  'cerulean',
  'gold',
  'gray',
  'green',
  'green yellow',
  'indigo',
  'orange',
  'red',
  'red orange',
  'red violet',
  'scarlet',
  'purple',
  'violet red',
  'white',
  'yellow',
  'yellow green',
  'yellow orange',
];
