import { Box, FormLabel, Input, Select, Stack, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { labelStyle } from '../../../../utils/theme';

export const FormContentWrapper = ({ title, children }) => {
  return (
    <Stack>
      <Text textStyle='h3' pb='5'>
        {title}
      </Text>
      {children}
    </Stack>
  );
};

export const SelectInputField = ({ value, setVehicleListingDetails, name, label, options, placeholder }) => {
  return (
    <Box w='100%'>
      <FormLabel {...labelStyle}>{label}</FormLabel>
      <Select
        value={value || ''}
        placeholder={placeholder}
        onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, [name]: e.target.value }))}
      >
        {options?.map((opt, i) => (
          <Fragment key={nanoid()}>
            {/* {i === 0 && (
              <option value='' disabled>
                {defaultValue}
              </option>
            )} */}

            <option value={opt?.toLowerCase()}>{opt}</option>
          </Fragment>
        ))}
      </Select>
    </Box>
  );
};
export const InputField = ({ vehicleListingDetails, setVehicleListingDetails, name, label, wrapperProps, ...inputProps }) => {
  return (
    <Box w='100%' {...wrapperProps}>
      <FormLabel {...labelStyle}>{label}</FormLabel>
      <Input
        value={vehicleListingDetails?.name}
        onChange={(e) => setVehicleListingDetails((prev) => ({ ...prev, [name]: e.target.value }))}
        {...inputProps}
      />
    </Box>
  );
};
