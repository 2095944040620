import { Box, HStack, Stack, StackDivider, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import { PageWrapper } from '../../../../components/common/PageWrapper';
import { SettingHead } from './SettingHead';

export const NotificationSettings = () => {
  return (
    <PageWrapper>
      <Box maxW='600px' mt='8' mb='12'>
        <SettingHead title='Notifications' sub='Set your notifications' />
        <Stack divider={<StackDivider border='blackAlpha.500' />} my='14'>
          <HStack justify='space-between' pt='5'>
            <Box>
              <Text>Product updates</Text>
              <Text fontSize='16px' textStyle='h4'>
                Receive notification on incoming messages
              </Text>
            </Box>
            <Box>
              <Switch colorScheme='red' />
            </Box>
          </HStack>
          <HStack justify='space-between' pt='5'>
            <Box>
              <Text>Draft reminders</Text>
              <Text fontSize='16px' textStyle='h4'>
                Receive upload drafts reminders
              </Text>
            </Box>
            <Box>
              <Switch colorScheme='red' />
            </Box>
          </HStack>
          <HStack justify='space-between' pt='5'>
            <Box>
              <Text>Promotions and tips</Text>
              <Text fontSize='16px' textStyle='h4'>
                Receive notification on sold cars
              </Text>
            </Box>
            <Box>
              <Switch colorScheme='red' />
            </Box>
          </HStack>
          <HStack justify='space-between' pt='5'>
            <Box>
              <Text>Security support</Text>
              <Text fontSize='16px' textStyle='h4'>
                Receive messages about suspected login
              </Text>
            </Box>
            <Box>
              <Switch colorScheme='red' />
            </Box>
          </HStack>
        </Stack>
      </Box>
    </PageWrapper>
  );
};
