import { Box, FormLabel, HStack, Stack, Select as ChakraSelect, Input } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { labelStyle } from '../../../../utils/theme';
import { useGetAllCarMakesQuery, useGetMakeModelsQuery } from '../../../public-pages/home/components/service';
import { FormContentWrapper, SelectInputField } from '../../add-new-vehicle/components/FormContentWrapper';
import Select from 'react-select';

export const EditListingInformation = ({ carDetails, setCarDetails }) => {
  const { data: carMakes } = useGetAllCarMakesQuery();
  const carModel = carMakes?.data?.find((make) => make?.name === carDetails?.make);
  const { data: model } = useGetMakeModelsQuery(carModel?.id || carDetails?.make?.id);

  const makeOptions = model?.data?.map((mod) => ({ ...mod, label: mod?.name, value: mod?._id }));

  return (
    <FormContentWrapper title='Listing Information'>
      <Stack spacing='10'>
        <HStack>
          <Box w='100%'>
            <FormLabel m='0'>Make</FormLabel>
            <ChakraSelect
              value={carDetails?.make?.name || carDetails?.make}
              // defaultValue={carDetails?.make?.name}
              onChange={(e) => setCarDetails((prev) => ({ ...prev, make: e.target.value }))}
            >
              {carMakes?.data?.map((make, i) => (
                <Fragment key={nanoid()}>
                  <option disabled={make?.disabled} value={make?.name}>
                    {make?.name}
                  </option>
                </Fragment>
              ))}
            </ChakraSelect>
          </Box>
          <Box w='100%'>
            <FormLabel m='0'>Model</FormLabel>
            <Select options={makeOptions} placeholder={carDetails?.model?.name} onChange={(opt) => setCarDetails((prev) => ({ ...prev, model_id: opt.id }))} />
          </Box>
        </HStack>

        <HStack justify='space-between'>
          <Box w='100%'>
            <FormLabel {...labelStyle}>Year</FormLabel>
            <Input value={carDetails?.year} onChange={(e) => setCarDetails((prev) => ({ ...prev, year: e.target.value }))} />
          </Box>

          <Box w='100%'>
            <FormLabel {...labelStyle}>VIN</FormLabel>
            <Input value={carDetails?.vin} onChange={(e) => setCarDetails((prev) => ({ ...prev, vin: e.target.value }))} />
          </Box>
        </HStack>

        <HStack>
          <Box w='100%'>
            <FormLabel {...labelStyle}>Milleage</FormLabel>
            <Input value={carDetails?.Mileage || carDetails?.mileage} onChange={(e) => setCarDetails((prev) => ({ ...prev, Mileage: e.target.value }))} />
          </Box>

          <SelectInputField
            label='Warranty'
            options={['No Warranty', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']}
            name='warranty'
            value={carDetails?.warranty}
            setVehicleListingDetails={setCarDetails}
          />
        </HStack>

        <HStack rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
          <SelectInputField
            label='Fuel type'
            name='fuel_type'
            options={['Gasoline/Petrol', 'Diesel', 'Electric']}
            value={carDetails?.fuel_type}
            setVehicleListingDetails={setCarDetails}
          />
          <SelectInputField
            label='Transmission'
            name='transmission'
            options={['Manual', 'Automatic']}
            value={carDetails?.transmission}
            setVehicleListingDetails={setCarDetails}
          />
        </HStack>
      </Stack>
    </FormContentWrapper>
  );
};
