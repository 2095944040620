import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogBody,
  Box,
} from '@chakra-ui/react';

export function CautionAlertDialog({ onContinue, button, cautionTitle, agree, disagree, buttonProps, children, size, yesLoading }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const BTN = {
    _focus: { boxShadow: 'none' },
    color: 'white',
    isLoading: yesLoading,
  };

  const handleClick = () => {
    onContinue(true);
    onClose();
  };

  return (
    <>
      <Box _focus={{ boxShadow: 'none', bg: buttonProps?.bg, border: 'none' }} _hover={{ opacity: '0.8' }} onClick={onOpen} {...buttonProps}>
        {button}
      </Box>

      <AlertDialog motionPreset='slideInBottom' onClose={onClose} isOpen={isOpen} isCentered size={size || 'sm'}>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{cautionTitle}</AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter color='white' pt='3rem' gap='1rem'>
            <Button variant='outline' color='teal.500' onClick={onClose} _focus={{ boxShadow: 'none' }} w='70px'>
              {disagree || 'No'}
            </Button>
            <Button w='70px' {...BTN} onClick={handleClick} bg='crimson' ml={3}>
              {agree || 'Yes'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
