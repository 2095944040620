import { Box, Center, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { PUBLIC_PATHS } from '../../../app/routes';
import { DescriptionAndPrice } from './components/Description';
import { PopularVehicles } from './components/PopularVehicles';
import { useGetCarFullDetailsQuery } from '../inventory/service';
import { FullPageLoader } from '../../../components/common/FullPageLoader';
import { nanoid } from '@reduxjs/toolkit';

import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { FaImage } from 'react-icons/fa';
import { useEffect } from 'react';

export const FullVehicleDetails = () => {
  const { carID } = useParams();
  const { data: carDetails, isLoading } = useGetCarFullDetailsQuery(carID);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper>
      <Stack spacing='10' pt='10'>
        <Flex align='center' gap='2rem'>
          <Link to={PUBLIC_PATHS.INVENTORY}>
            <Center bg='gray.200' borderRadius='5px' h='45px' w='45px'>
              <TiArrowBack size={20} />
            </Center>
          </Link>
          <Text textStyle='h1'>
            {carDetails?.data?.year} {carDetails?.data?.name}{' '}
          </Text>
        </Flex>

        <Flex gap='2rem' h='55vh' direction={['column', 'column', 'row']}>
          <Box flex='1.8'>
            <PhotoProvider speed={() => 800} easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}>
              <PhotoView src={carDetails?.data?.images[0].url || '/images/benz.svg'}>
                <Image
                  shadow='sm'
                  borderRadius='5px'
                  objectFit='cover'
                  w='100%'
                  h='100%'
                  maxH={['300px', '', '100%']}
                  src={carDetails?.data?.images[0].url || '/images/benz.svg'}
                />
              </PhotoView>
            </PhotoProvider>
          </Box>
          <Stack pos='relative' flex='1' spacing='5' overflowY='auto' justify='space-between' direction={['row', 'row', 'column']}>
            <PhotoProvider>
              {carDetails?.data?.images?.map((img) => (
                <PhotoView key={nanoid()} src={img?.url || '/images/front.png'}>
                  <Image
                    shadow='sm'
                    borderRadius='5px'
                    h='25vh'
                    w={['49%', '49%', '100%']}
                    maxW='450px'
                    objectFit='cover'
                    src={img?.url || '/images/front.png'}
                    alt='car'
                  />
                </PhotoView>
              ))}
            </PhotoProvider>
            <HStack className='more__images'>
              <FaImage />
              <Text as='small'>view {carDetails?.data?.images?.length}+ photos</Text>
            </HStack>
          </Stack>
        </Flex>
        <DescriptionAndPrice carDetails={carDetails?.data} />
        <PopularVehicles />
      </Stack>
    </PageWrapper>
  );
};
