import { FormLabel, HStack, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { carTypes, colorsArr } from '../../add-new-vehicle/components/extras';
import { FormContentWrapper, SelectInputField } from '../../add-new-vehicle/components/FormContentWrapper';
import { labelStyle } from '../../../../utils/theme';

export const EditMajorFeatures = ({ carDetails, setCarDetails }) => {
  return (
    <FormContentWrapper title='Major Features'>
      <FormLabel {...labelStyle}>Car(Body) type</FormLabel>{' '}
      <Select value={carDetails?.body_style} onChange={(e) => setCarDetails((prev) => ({ ...prev, body_style: e.target.value }))}>
        {carTypes?.map((make, i) => (
          <Fragment key={nanoid()}>
            <option value={make?.value}>{make.label}</option>
          </Fragment>
        ))}
      </Select>
      <HStack pt='5' rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
        <SelectInputField
          value={carDetails?.door}
          setVehicleListingDetails={setCarDetails}
          placeholder={'number of doors'}
          label='Doors'
          name='door'
          options={['2', '4']}
        />

        <SelectInputField
          label='Drive Train'
          value={carDetails?.drive_train}
          setVehicleListingDetails={setCarDetails}
          name='drive_train'
          options={['Rear Wheel Drive – RWD', 'Four Wheel Drive – 4WD', 'All-Wheel Drive – AWD', 'Front Wheel Drive – FWD']}
        />
      </HStack>
      <HStack pt='5' rowGap={['5']} spacing={[0, 2]} flexDirection={['column', 'row']}>
        <SelectInputField value={carDetails?.interior_color} setVehicleListingDetails={setCarDetails} label='Interior Color' name='interior_color' options={colorsArr} />
        <SelectInputField value={carDetails?.exterior_color} setVehicleListingDetails={setCarDetails} label='Exterior Color' name='exterior_color' options={colorsArr} />

        <SelectInputField value={carDetails?.seat} setVehicleListingDetails={setCarDetails} label='Seat' name='seat' options={['2', '4', '6', '8']} />
      </HStack>
      <RadioGroup value={carDetails?.category} onChange={(val) => setCarDetails((prev) => ({ ...prev, category: val }))} py='5'>
        <FormLabel>Category</FormLabel>
        <Stack direction='row'>
          <Radio value='trending'>Trending</Radio>
          <Radio value='popular'>Popular</Radio>
          {/* <Radio value='favourite'>Favourite</Radio> */}
          <Radio value='used'>Used</Radio>
          <Radio value='new'>New</Radio>
        </Stack>
      </RadioGroup>
    </FormContentWrapper>
  );
};
