import { Center, Img, Text } from '@chakra-ui/react';

export const EmptyState = ({ msg, h }) => {
  return (
    <Center w='100%' h={h || '45vh'} flexDirection='column'>
      <Img src='/images/Undraw.svg' alt='empty' />
      <Text fontSize='1.5rem'>{msg || 'Car type not found'}</Text>
    </Center>
  );
};
