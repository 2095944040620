import { Box, Flex, HStack, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AdminFooter } from '../common/AdminFooter';
import { CgArrowLongLeft } from 'react-icons/cg';
import { PageWrapper } from '../common/PageWrapper';
import { PROTECTED_PATHS } from '../../app/routes';

export const AddNewListingLayout = () => {
  const navigate = useNavigate();
  return (
    <Box bg='white'>
      <PageWrapper shadow='sm'>
        <HStack justify={'center'}>
          <HStack cursor='pointer' flex='0.8' onClick={() => navigate(PROTECTED_PATHS.ADMIN_LISTING)}>
            <CgArrowLongLeft size={20} />
            <Text>Back to Dashboard</Text>
          </HStack>

          <Flex flex='1'>
            <Img src='/images/logo.svg' alt='logo' />
          </Flex>
        </HStack>
      </PageWrapper>
      <PageWrapper py='10'>
        <VStack textAlign='center' mx='auto' maxW='550px'>
          <Text fontWeight='bold' textStyle='h2'>
            Add New Vehicle
          </Text>
          <Text fontSize='15px'>Make sure you have filled in all the necessary fields and have uploaded all the required files.</Text>
        </VStack>
        <Outlet />
      </PageWrapper>
      <AdminFooter />
    </Box>
  );
};
