import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react';
import { HiUserCircle, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../../app/routes';

export const SettingTypeCard = ({ title, subTitle, icon, to, data }) => {
  return (
    <Link to={`${to || PROTECTED_PATHS.ADMIN_PROFILE || '/'}`} state={data}>
      <HStack className='settings__type__card' shadow='md' minW='350px'>
        <Center bg='brand.100' p='3' borderRadius='5px'>
          {icon || <HiUserCircle size={20} />}
        </Center>
        <Stack>
          <Text textStyle='h4' fontSize='16px'>
            {title}
          </Text>
          <Text>{subTitle}</Text>
        </Stack>
        <Box className='arrow' color='brand.500'>
          <HiOutlineArrowNarrowRight size={25} />
        </Box>
      </HStack>
    </Link>
  );
};
