import { Avatar, Box, Button, Flex, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { MdModeEdit, MdSecurity, MdNotifications } from 'react-icons/md';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { SettingTypeCard } from './components/SettingTypeCard';
import { useGetUserProfileQuery, useUploadProfilePhotoMutation } from '../../../redux/features/api/profileApiSlice';
import { FullPageLoader } from '../../../components/common/FullPageLoader';
import { useSelector } from 'react-redux';
import { userRole } from '../../../redux/features/userSlice';
import { errorToast, successToast } from '../../../components/common/ToastHandler';
import { gotoAccountInformation, gotoLoginDetails, gotoNotification } from './components/extra';

export const ProfileSettings = () => {
  const user = useSelector(userRole);
  const { data: profile, isLoading, refetch } = useGetUserProfileQuery();
  const [uploadProfilePhoto, { isLoading: uploadingProfilePhoto }] = useUploadProfilePhotoMutation();

  const handlePhotoUpload = async (e) => {
    if (!e.target.files) return;

    const formData = new FormData();
    formData.append('profile_image', e.target.files[0]);

    await uploadProfilePhoto(formData)
      .unwrap()
      .then((data) => successToast(data.message))
      .catch(() => errorToast())
      .finally(() => refetch());
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper>
      <Stack>
        <Text textStyle='h3'>Account Settings</Text>
        <Flex py='10' justify='center'>
          <VStack spacing={0}>
            <Avatar size='2xl' src={profile?.data?.profile_image} name={profile?.data?.name} />
            <Text textStyle='h3' pt='4'>
              {profile?.data?.name}
            </Text>
            <Text>{profile?.data?.email}</Text>
          </VStack>
          <Box pt='3rem'>
            <Input display='none' name='photo' id='photo' type='file' accept='image/*' onChange={handlePhotoUpload} />
            <Button isLoading={uploadingProfilePhoto} cursor='pointer' as='label' htmlFor='photo' leftIcon={<MdModeEdit />} variant='outline'>
              Edit Photo
            </Button>
          </Box>
        </Flex>
        <Flex wrap='wrap' columnGap='2%' rowGap='3rem' justify='center'>
          <SettingTypeCard title='Account information' subTitle={'Your personal Information'} to={gotoAccountInformation(user)} />

          <SettingTypeCard
            title='Login details'
            subTitle='Password & security questions'
            icon={<MdSecurity size={20} />}
            to={gotoLoginDetails(user)}
            data={profile}
          />

          <SettingTypeCard
            title='Notifications'
            subTitle='Set your email notifications'
            icon={<MdNotifications size={20} />}
            to={gotoNotification(user)}
          />
          {/* <SettingTypeCard
            title='Admin information'
            subTitle='Connected apps and services'
            icon={<BsEyeFill size={20} />}
            to={SUPER_ADMIN_ACCOUNT_INFORMATION}
          /> */}
        </Flex>
      </Stack>
    </PageWrapper>
  );
};
