import { Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Paginator } from '../../../components/common/Paginator';
import { CustomDrawer } from '../../../components/common/CustomDrawer';
import { ViewAdminDetails } from './ViewAdminDetails';
import { useGetUserByRoleQuery } from '../../../redux/features/api/superAdminApilSlice';
import { formatDate } from '../../../utils/utils';
import { FullPageLoader } from '../../../components/common/FullPageLoader';

export const AllAdminsTable = () => {
  const { data: allAdmins, isLoading } = useGetUserByRoleQuery('admin');
  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  const displayedAdmins = allAdmins?.slice(pagesVisited, pagesVisited + itemsPerPage);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <TableContainer>
      <Table variant='Unstyled' size='lg'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Date Added</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody fontSize='14px' minH='80vh'>
          {displayedAdmins?.map((admin, i) => (
            <Tr key={nanoid()}>
              <Td>{admin?.name}</Td>
              <Td>{admin?.email}</Td>

              <Td>{formatDate(admin?.created_at)}</Td>
              <Td>
                <CustomDrawer btn='View' variant='link'>
                  <ViewAdminDetails admin={admin} />
                </CustomDrawer>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify='center' py='10'>
        <Paginator data={allAdmins?.data} itemsPerPage={itemsPerPage} setPageNumber={setPageNumber} />
      </Flex>
    </TableContainer>
  );
};
