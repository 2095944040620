import { Center, Flex, Heading, Img, Stack, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { BsFillCheckCircleFill, BsSearch } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../../app/routes';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { userRole } from '../../../../redux/features/userSlice';
import { carLogos, priceRange } from './extras';
import { useGetAllCarMakesQuery, useGetMakeModelsQuery } from './service';

export const HeroBanner = () => {
  const [skip, setSkip] = useState(true);
  const user = useSelector(userRole);
  const [price, setPrice] = useState('');
  const [carMake, setCarMake] = useState(null);
  const [selectModel, setSelectModel] = useState(null);
  const { data: allCarMakes, isLoading } = useGetAllCarMakesQuery();
  const { data: carModels } = useGetMakeModelsQuery(carMake?.id, { skip });
  const navigate = useNavigate();

  useEffect(() => {
    carMake?.id && setSkip(false);
  }, [carMake?.id]);

  const makeOptions = useMemo(() => allCarMakes?.data?.map((make) => ({ ...make, label: make?.name, value: make?.id })), [allCarMakes?.data]);
  const modelOptions = useMemo(() => carModels?.data?.map((model) => ({ ...model, label: model?.name, value: model?.id })), [carModels?.data]);

  const whichInventroty = () => {
    switch (user) {
      case 'admin':
        return PROTECTED_PATHS.ADMIN_LISTING;

      case 'user':
        return PROTECTED_PATHS.INVENTORY;

      case 'super-admin':
        return PROTECTED_PATHS.SUPER_ADMIN_LISTING;

      default:
        return PUBLIC_PATHS.INVENTORY;
    }
  };

  const navigateToInvetory = () => {
    // console.log('{ selectModel, carMake, price }', { selectModel, carMake, price });
    carMake || selectModel || price ? navigate(whichInventroty(), { state: { selectModel, carMake, price } }) : navigate(whichInventroty());
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack as='section'>
      <Stack loading='eager' className='banner_hero' justify='center' pl='10%' p={[5]}>
        <Stack w='70%' minW={['100%', '100%', '100%', '700px']} flex='0.8' justify='space-between' color='white'>
          <Stack>
            <Heading as='h1' pt='12' pb='3'>
              Find Affordable Vehicles in the U.S and <br /> Canada
            </Heading>
            <Flex wrap='wrap' borderRadius='5px' bg='white' p={['2']} rowGap={['1.2rem']} columnGap={['1rem']}>
              <Select
                // onChange={(selected) => setCarMakeId(selected?.value)}
                onChange={(make) => setCarMake(make)}
                placeholder='Make'
                className='react-select-container'
                classNamePrefix='react-select'
                options={makeOptions || []}
              />
              <Select
                onChange={(selected) => setSelectModel(selected)}
                options={modelOptions}
                placeholder='Model'
                className='react-select-container'
                classNamePrefix='react-select'
                noOptionsMessage={() => 'Select a make first'}
              />

              <Select
                onChange={(selected) => setPrice(selected.value)}
                options={priceRange}
                placeholder='Price'
                className='react-select-container'
                classNamePrefix='react-select'
              />

              {/* <Input value={price} flex='0.9' fontSize={14} color='blackAlpha.700' type='number' placeholder='Max-price' onChange={(e) => setPrice(e.target.value)} /> */}
              <PrimaryButton flex='1' minW={['120px']} leftIcon={<BsSearch />} onClick={navigateToInvetory}>
                Search
              </PrimaryButton>
            </Flex>
          </Stack>
          <Flex gap='5'>
            <Center borderRadius='5px' px='3' py='2' bg='brand.500'>
              <BsFillCheckCircleFill color='white' size={20} />
            </Center>
            <Stack justifySelf={'flex-end'} spacing='0'>
              <Text fontWeight='bold'>Distribution licence</Text>
              <Text as='small'>Fully fledged dealership company</Text>
            </Stack>
          </Flex>
        </Stack>
      </Stack>

      {/* <CarLogos /> */}

      <Flex py='16' columnGap='7%' rowGap={'5rem'} wrap='wrap' justify='center'>
        {carLogos.map((logo, i) => (
          <Img key={i} src={logo} alt='makes' w='60px' h='50px' />
        ))}
      </Flex>
      {/* <Img src={'/images/Makes.svg'} alt='makes' /> */}
    </Stack>
  );
};
