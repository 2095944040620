export const formatInThousands = (number = 0) => new Intl.NumberFormat().format(number);

export const timeout = (callback, duration = 4000) => {
  setTimeout(() => {
    callback();
  }, duration);
};

//function to remove the slug
export const dynamicPathhandler = (path) => {
  const route = path
    ?.split('/')
    ?.slice(1, -1)
    ?.map((el) => el.replace(/^/, '/'))
    .join('');

  return route;
};

export const disable = (...props) => props?.every((props) => Boolean(props));

export const disableForObject = (obj) => Object.values(obj).every((input) => input);

export const maskNumberFromFront = (number = '00', digitsToReavel = 0) => number?.slice(-digitsToReavel)?.padStart(number?.length, 'X');

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const opt = { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric' };
export const formatDate = (date, options) => {
  const covertedDate = new Date(date);
  return new Intl.DateTimeFormat('en-GB', options || opt).format(covertedDate);
  // return fd.format(date);
};
// const d = formatDate(new Date(), { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric' });

export function convertNumberToHoursAndMin(num) {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  return `${hours}:${minutes}`;
}

export function validateEmail($email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test($email);
}
