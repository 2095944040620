import { Center, Flex, Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { FilterBy } from './components/FilterBy';
import { Vehicle } from './components/Vehicle';
import { useFilterSearchQuery } from './service';
import useDebounce from '../../../hooks/useDebounce';

export const InventoryPage = () => {
  const { state } = useLocation();
  const [params, setParams] = useState({});
  const { data: searchResult } = useFilterSearchQuery(params);
  const [searchName, setSearchName] = useState('');
  const debouncedNameSearch = useDebounce(searchName, 1000);

  useEffect(() => {
    state?.car?.name && setParams({ make: state?.car?.name });
  }, [state?.car]);

  useEffect(() => {
    state?.carMake?.name && setParams((prev) => ({ ...prev, make: state?.carMake?.name }));
  }, [state?.carMake?.name]);

  useEffect(() => {
    state?.selectModel?.name && setParams((prev) => ({ ...prev, model: state?.selectModel?.name }));
  }, [state?.selectModel?.name]);

  useEffect(() => {
    state?.price && setParams((prev) => ({ ...prev, price: `0-${state?.price}` }));
  }, [state?.price]);

  useEffect(() => {
    debouncedNameSearch && setParams((prev) => ({ ...prev, name: debouncedNameSearch }));
  }, [debouncedNameSearch]);

  return (
    <PageWrapper>
      <Stack spacing='8'>
        <InputGroup shadow='md' size='lg' borderRadius='5px'>
          <InputLeftElement w='50px' borderRadius='10px' pointerEvents='none'>
            <Center borderRadius='10px' color={'#fff'} bg='brand.500' w='90%' h='80%'>
              <BiSearchAlt2 />
            </Center>
          </InputLeftElement>
          <Input onChange={(e) => setSearchName(e.target.value)} border='none' pl='5rem' bg='lightGray' placeholder='Search...' />
        </InputGroup>

        <Flex justify='space-between' direction={['column', 'column', 'row']}>
          <Vehicle params={params} searchResult={searchResult} />
          <FilterBy params={params} setParams={setParams} />
        </Flex>
      </Stack>
    </PageWrapper>
  );
};

export const dynamicSearchEndpoint = (params) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let url = '';

  if (params?.name) url += `&filter[name]=${params.name}`;
  if (params?.year) url += `&filter[year]=${params.year}`;
  if (params?.make) url += `&filter[make]=${params.make}`;
  if (params?.model) url += `&filter[model]=${params.model}`;
  if (params?.price) url += `&filter[price]=${params.price}`;
  if (params?.exterior_color) url += `&filter[exterior_color]=${params.exterior_color}`;
  if (params?.interior_color) url += `&filter[interior_color]=${params.interior_color}`;
  if (params?.transmission) url += `&filter[transmission]=${params.transmission}`;

  return `${baseUrl}/search?${url}`;
};
