import { Box, Center, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AuthPagesBody } from '.';
import { GrFormViewHide, GrFormView } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { PUBLIC_PATHS } from '../../app/routes';
import { PrimaryButton } from '../../components/common/CustomButtons';
import { useForm } from 'react-hook-form';
import { useSignUpMutation } from './service';
import { errorToast, successToast } from '../../components/common/ToastHandler';

export const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { register, formState, handleSubmit } = useForm({ defaultValues: { email: '', password: '', name: '' } });
  const [signUp, { isLoading }] = useSignUpMutation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await signUp(data)
      .unwrap()
      .then((data) => {
        successToast(data.data.message, { autoClose: 50000 });
        navigate(PUBLIC_PATHS.VERIFY_EMAIL);
        localStorage.setItem('registeredUser', JSON.stringify(data));
      })
      .catch((error) => errorToast(error?.data?.message));
  };
  return (
    <AuthPagesBody>
      {' '}
      <Center minH='75vh' flexDirection='column'>
        <FormControl as={'form'} maxW='400px' mx='auto' onSubmit={handleSubmit(onSubmit)}>
          <Text textStyle='h3' fontSize='2rem'>
            Welcome
          </Text>
          <Text fontSize='1rem' mb='2rem'>
            Please enter your details.
          </Text>
          <FormLabel m='0' htmlFor='name'>
            Name
          </FormLabel>
          <Input id='name' {...register('name', { required: 'name is required' })} bg='brand.100' placeholder='Ciroma Abekun' />
          <Text as='small' color='crimson'>
            {formState.errors?.name?.message}
          </Text>

          <FormLabel m='0' mt='2rem' htmlFor='email'>
            Email
          </FormLabel>
          <Input type='email' id='email' {...register('email', { required: 'Email is required' })} bg='brand.100' placeholder='example@gmail.com' />
          <Text as='small' color='crimson'>
            {formState.errors?.email?.message}
          </Text>

          <FormLabel m='0' htmlFor='password' mt='2rem'>
            Password
          </FormLabel>

          <InputGroup size='md'>
            <Input
              id='password'
              {...register('password', {
                required: 'Password is required ',
                minLength: { value: 8, message: 'Password must be 8 Characters' },
              })}
              bg='brand.100'
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
            />
            <InputRightElement width='4.5rem'>
              <Box as='span' cursor='pointer' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <GrFormViewHide size={20} /> : <GrFormView size={20} />}
              </Box>
            </InputRightElement>
          </InputGroup>
          <Text as='small' color='crimson'>
            {formState.errors?.password?.message}
          </Text>

          <Link to={PUBLIC_PATHS.FORGOT_PASSWORD}>
            <Text color='brand.500' fontWeight='semibold' py='5' textAlign='center'>
              Forgot Password
            </Text>
          </Link>
          <Flex flexDirection='column' gap='5'>
            <PrimaryButton isLoading={isLoading} type='submit'>
              Sign Up
            </PrimaryButton>

            {/* <Button leftIcon={<GrGoogle />}>Sign Up with Google</Button> */}
          </Flex>
          <Text textAlign='center' py='5'>
            Already have an account?
            <Link to={PUBLIC_PATHS.LOGIN} style={{ fontWeight: 'bold' }}>
              Login
            </Link>
          </Text>
        </FormControl>
      </Center>
    </AuthPagesBody>
  );
};
