import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { MdOutlineMarkChatRead } from 'react-icons/md';
import { GrClose } from 'react-icons/gr';
import { OutlinedButton, PrimaryButton } from '../../../../components/common/CustomButtons';
import { formatDate } from '../../../../utils/utils';
import { useDeleteMessageMutation, useUpadteMarkAsReadMutation } from '../../../../redux/features/api/messagesApiSlice';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';

export const ContactMessageDetails = ({ message, onClose }) => {
  const [deleteMessage, { isLoading }] = useDeleteMessageMutation();
  const [upadteMarkAsRead, { isLoading: updating }] = useUpadteMarkAsReadMutation();

  const handleDeleteMessage = async (id) => {
    await deleteMessage(id)
      .unwrap()
      .then((data) => {
        successToast(data?.message);
        onClose();
      })
      .catch((error) => {
        errorToast();
      });
  };

  const handleMarkAsRead = async (id) => {
    await upadteMarkAsRead(id)
      .unwrap()
      .then((data) => {
        successToast(data?.message);
        onClose();
      })
      .catch((error) => {
        errorToast();
      });
  };

  return (
    <Stack py='2' px='6'>
      <HStack justify='space-between' p='4' shadow='sm'>
        <Text textAlign='center' flex='1' textStyle='h3' fontSize='20px'>
          Enquiry message
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>

      <Stack align='center' py='5'>
        <HStack pb='5' gap='1rem'>
          <PrimaryButton leftIcon={<MdOutlineMarkChatRead />} size='sm' isLoading={updating} onClick={() => handleMarkAsRead(message.id)}>
            Mark as Read
          </PrimaryButton>
          <OutlinedButton leftIcon={<GrClose />} size='sm' isLoading={isLoading} onClick={() => handleDeleteMessage(message.id)}>
            Delete Message
          </OutlinedButton>
        </HStack>
        <Stack spacing='4' w='full' p='5' border='1px solid #ededed'>
          <Box textAlign='center'>
            <Text textStyle='h3'>{message?.name}</Text>
            <Text>{message?.email}</Text>
          </Box>

          <HStack justify='space-between' bg='brand.200' borderRadius='5px' px='2' py='3'>
            <Text>ID {message?.id}</Text>
            <Text>Customer ID</Text>
          </HStack>
          <HStack justify='space-between'>
            <Text color='gray'>Email address</Text>
            <Text>{message?.email}</Text>
          </HStack>
          <HStack justify='space-between'>
            <Text color='gray'>Date sent</Text>
            <Text>{formatDate(message?.created_at)}</Text>
          </HStack>
          <Text color='gray'>Message:</Text>
          <Text>{message?.message}</Text>
        </Stack>
        <Box w='full' py='5'>
          <PrimaryButton bg='#111' w='full' onClick={() => window.open(`mailto:${message?.email}?subject=Gabe Investment`)}>
            Email Customer
          </PrimaryButton>
        </Box>
      </Stack>
    </Stack>
  );
};
