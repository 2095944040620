import { Box, Flex, HStack, Img, Text, VStack } from '@chakra-ui/react';
import { CgArrowLongLeft } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../../app/routes';
import { PageWrapper } from '../../../../components/common/PageWrapper';

export const EditListingHeader = () => {
  const navigate = useNavigate();
  return (
    <Box bg='white'>
      <PageWrapper shadow='sm'>
        <HStack justify={'center'}>
          <HStack cursor='pointer' flex='0.8' onClick={() => navigate(PROTECTED_PATHS.ADMIN_LISTING)}>
            <CgArrowLongLeft size={20} />
            <Text>Back to Dashboard</Text>
          </HStack>

          <Flex flex='1'>
            <Img src='/images/logo.svg' alt='logo' />
          </Flex>
        </HStack>
      </PageWrapper>
      <PageWrapper py='10'>
        <VStack textAlign='center' mx='auto' maxW='550px'>
          <Text fontWeight='bold' textStyle='h2'>
            Edit Vehicle Listing
          </Text>
          <Text fontSize='15px'>Make sure you have filled in all the necessary fields and have uploaded all the required files.</Text>
        </VStack>
      </PageWrapper>
    </Box>
  );
};
