import { Box, HStack, Img, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { BsDot, BsThreeDots } from 'react-icons/bs';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import CustomModal from '../../../../components/common/CustomModal';
import { fallBackImg } from '../../../../components/common/extras';
import { formatInThousands } from '../../../../utils/utils';
import { IndividualListing } from './IndividualListing';

export const SingleListedVehicle = ({ item }) => {
  return (
    <HStack gap='2rem' border='2px solid #ececec' bg='white' wrap='wrap' pb={['2']}>
      <Img
        borderRadius='5px'
        width={['100%', '300px', '280px']}
        objectFit='cover'
        h={['100%', '200px', '180px']}
        src={item?.images[0]?.url || '/images/vehicle.png'}
        alt='vehicle'
        fallback={fallBackImg}
      />
      <Stack color='#9A9EA7' w='fit-content'>
        <Text>#ID34K23E</Text>
        <Text textStyle='h4'>
          {item?.year} {item?.name}
        </Text>
        <Text>{Number(item?.Mileage) || 'Unkown'} Miles</Text>
        <HStack>
          <Text>{item?.exterior_color}</Text>
          <Text alignItems='center' display='flex'>
            <BsDot size={20} />#{item?.ref_id}
          </Text>
          <HStack alignItems='center' display='flex'>
            <Box>
              <BsDot size={20} />
            </Box>
            <Text>{item?.warranty === 'no warranty' ? 'No waranty' : item.warranty === '1' ? '1yr waranty' : `${item?.warranty}yrs warranty`}</Text>
          </HStack>
        </HStack>
        <Text textStyle='h4'>
          $ {formatInThousands(item?.price)} {item?.fullName}
        </Text>
      </Stack>

      <Stack spacing='5' flex='0.9' align={['center', 'center', 'flex-end']} direction={['row', 'row', 'row', 'column']}>
        <CustomModal size='lg' btnTitle={<BsThreeDots color='black' size={25} />}>
          <IndividualListing item={item} />
        </CustomModal>

        <Box>
          {item?.availability ? (
            <PrimaryButton bg='brand.100' color='brand.500'>
              Active
            </PrimaryButton>
          ) : (
            <PrimaryButton isDisabled={true} bg='brand.100' color='brand.500'>
              Sold
            </PrimaryButton>
          )}
        </Box>
        <Text>{item?.warranty === 'no warranty' ? 'No waranty' : item.warranty === '1' ? '1yr waranty' : `${item?.warranty}yrs warranty`}</Text>
      </Stack>
    </HStack>
  );
};
