import { Box, Flex, Img, Stack, Text } from '@chakra-ui/react';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { Testimonial } from '../home/components/Testimonial';

export const AboutUs = () => {
  return (
    <PageWrapper>
      <Stack minH='100vh'>
        <Flex gap='2rem' pt='5vh' direction={['column', 'column', 'column', 'column', 'row']}>
          <Stack flex={1} fontSize='1rem'>
            <Text textStyle='h3' fontSize={'2.5rem'} color='brand.400'>
              About Us
            </Text>
            <Text>At Gabe Investments, we deal with all-things car purchases. Since 2014, we've helped many individuals purchase quality cars at affordable prices.</Text>
            <Text>
              With over 8 years of solid experience delivering vehicles to satisfied customers around the world, Gabe Investments, is a licensed car dealer, offering only
              the best services
            </Text>
          </Stack>
          <Flex flex='1' gap='3rem' direction={['column', 'column', 'row']}>
            <Img src='/images/Rectangle16.svg' alt='car imgage' />
            <Img src='/images/Rectangle17.svg' alt='car imgage' />
          </Flex>
        </Flex>

        <Flex justify='space-between' py='10vh' gap='3rem' direction={['column', 'column', 'column', 'row']}>
          <Box flex='1'>
            <Img src='/images/Rectangle19.svg' alt='car image' w='100%' />
          </Box>
          <Box pos='relative' zIndex={10} flex='1'>
            <Img src='/images/Rectangle18.svg' alt='car image' flex='1' w='100%' />
            <Img src='/images/frame27.png' alt='dots' className='car-img-with-dots' />
          </Box>
        </Flex>

        <PageWrapper>
          <Testimonial />
        </PageWrapper>
      </Stack>
    </PageWrapper>
  );
};
