import { Box, Center, Flex, FormControl, FormLabel, Img, Input, Stack, Text, Textarea } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../components/common/CustomButtons';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { usePublicContactUsMutation } from '../../../redux/features/api/contactUs';
import { labelStyle } from '../../../utils/theme';
import { errorToast, successToast } from '../../../components/common/ToastHandler';
import { useGetUserProfileQuery } from '../../../redux/features/api/profileApiSlice';

export const ContactUsPage = () => {
  const { data: userProfile } = useGetUserProfileQuery();
  const [formValues, setFormValues] = useState({});
  const [publicContactUs, { isLoading }] = usePublicContactUsMutation();

  const onChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setFormValues(userProfile?.data);
  }, [userProfile?.data]);

  const sendMessage = (e) => {
    e.preventDefault();
    const payload = { name: formValues.name, email: formValues.email, message: formValues.message, phone_number: formValues.phone_number };

    publicContactUs(payload)
      .unwrap()
      .then((data) => {

        successToast(data.message);
      })
      .catch((error) => {
        errorToast();
      });
  };

  return (
    <PageWrapper>
      <Flex minH='90vh' my={['10']} mt={[0, 0]} gap='2rem' align='center'>
        <Box flex='1.3' h='100%' display={['none', 'none', 'none', 'block']}>
          <Img objectFit='cover' h='100%' w='100%' src='/images/contact-image.png' alt='contact us' />
        </Box>
        <Center flex='1'>
          <FormControl as='form' py='3rem' maxW='600px' isRequired onSubmit={sendMessage}>
            <Text textStyle='h3' pb='3' color='brand.400'>
              Contact Us
            </Text>
            <Text>Send us a message now and let's help you get your best deals yet.</Text>
            <Text pb='5'>We'll talk to you about your preferred choices, make recommendations and help in securing your vehicle.</Text>
            <Stack spacing='5'>
              <Box>
                <FormLabel {...labelStyle}>Your name</FormLabel>
                <Input autoFocus name='name' value={formValues?.name || ''} onChange={onChange} />
              </Box>
              <Box>
                <FormLabel {...labelStyle}>Email address</FormLabel>
                <Input type='email' name='email' value={formValues?.email || ''} onChange={onChange} />
              </Box>
              <Box>
                <FormLabel {...labelStyle}>Phone number</FormLabel>
                <Input type='number' name='phone_number' value={formValues?.phone_number || ''} onChange={onChange} />
              </Box>
              <Box>
                <FormLabel {...labelStyle}>Message</FormLabel>
                <Textarea resize='none' rows='5' placeholder='Type here' name='message' onChange={onChange} />
              </Box>
            </Stack>
            <Flex pt='10'>
              <PrimaryButton isLoading={isLoading} type='submit' w='100%'>
                Send Message
              </PrimaryButton>
            </Flex>
          </FormControl>
        </Center>
      </Flex>
    </PageWrapper>
  );
};
