import { Flex, Stack, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { EmptyState } from '../../../../components/common/EmptyState';
import { FullPageLoader } from '../../../../components/common/FullPageLoader';
import { useGetCarByCategoryQuery } from './service';
import { TrendingCard } from './TrendingCard';

export const TrendingNearYou = () => {
  const { data: trendingCars, isLoading, refetch } = useGetCarByCategoryQuery('trending');

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack as='section'>
      <Text textStyle='h3' py='5'>
        Trending Near You
      </Text>
      <Flex gap='2rem' wrap='wrap' justify={['center', 'center', 'flex-start']}>
        {trendingCars?.data?.map((trendingCar) => (
          <TrendingCard trendingCar={trendingCar} key={nanoid()} isLoading={isLoading} refetch={() => refetch()} />
        ))}
        {!trendingCars?.data?.length && <EmptyState msg='No Trending Cars at this moment' />}
      </Flex>
    </Stack>
  );
};
