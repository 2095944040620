import axios from 'axios';
import { PROTECTED_PATHS } from '../../../../app/routes';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';
import { API_ENDPOINTS } from '../../../../services/api';

export const addNewVehicleListing = async (formData, navigate, user, setLoading) => {
  const token = JSON.parse(JSON.parse(localStorage.getItem('persist:#gbe#xp&st'))?.user)?.user?.data?.token || null;
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}${API_ENDPOINTS.ALL_CARS_INVENTORIES}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'multipart/form-data',
        'Access-Control-Allow-headers': 'Content-Type',
        Authorization: `Bearer ${token}`,
      },
    });

    successToast('New Car Listed Successfully');
    setLoading(false);
    navigate(user === 'super-admin' ? PROTECTED_PATHS.SUPER_LISTING_CREATED : PROTECTED_PATHS.LISTING_CREATED);
  } catch (error) {
    errorToast();
    setLoading(false);
  }
};
