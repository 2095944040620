import { Box, Flex, FormControl, FormLabel, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { errorToast, infoToast, successToast, warningToast } from '../../../../components/common/ToastHandler';
import { disableForObject } from '../../../../utils/utils';
import http, { API_ENDPOINTS } from '../../../../services/api';

export const ChangePassword = ({ onClose }) => {
  const [formValues, setFormValues] = useState({ old_password: '', new_password: '', confirmPassword: '' });
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdatePassword = async () => {
    const { new_password, confirmPassword, old_password } = formValues;
    if (confirmPassword !== new_password) return infoToast('New Passwords do not match');
    if (new_password?.length < 8) return warningToast('Password must at least 8 Characters');
    const payload = { new_password, old_password };

    try {
      setLoading(true);
      const { data } = await http.put(API_ENDPOINTS.UPDATE_PASSWORD, payload);

      successToast(data.message);
      onClose();
    } catch (error) {
      errorToast(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack>
      <HStack justify='space-between' p='8' shadow='sm'>
        <Text textStyle='h3' fontSize={'20px'}>
          Change your Password
        </Text>
        <Box>
          <GrClose size={18} onClick={onClose} cursor='pointer' />
        </Box>
      </HStack>
      <FormControl as='form' p='8'>
        <Stack spacing='5'>
          <FormLabel as='label' fontSize={'14px'}>
            Old Password
            <Input onChange={onChange} name='old_password' placeholder='Enter your old Password' />
          </FormLabel>
          <FormLabel as='label' fontSize={'14px'}>
            Enter new Password
            <Input onChange={onChange} name='new_password' placeholder='Enter your New Password' />
          </FormLabel>
          <FormLabel as='label' fontSize={'14px'}>
            Confirm new Password
            <Input onChange={onChange} name='confirmPassword' placeholder='Confirm Password' />
          </FormLabel>
        </Stack>
        <Flex py='10'>
          <PrimaryButton isDisabled={!disableForObject(formValues)} isLoading={loading} w='100%' onClick={handleUpdatePassword}>
            Update Password
          </PrimaryButton>
        </Flex>
      </FormControl>
    </Stack>
  );
};
